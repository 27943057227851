import React from "react";
import "./HeroSectionDesktop.css";
import ibizamb from "./andi.png";
import travela from "./partners/tr.png";
import dexa from "./partners/dexa.png";
import ele from "./partners/ele.png";
import fio from "./partners/fio.png";
import folk from "./partners/folk.png";
import ethe from "./partners/ethe.png";
import dtra from "./partners/dtra.png";
import quad from "./partners/quad.svg";
import mada from "./partners/madavi.png";
import bar from "./partners/barcelo.png";
import oc from "./partners/occi.png";
import xre from "./partners/xre.png";
import kilma from "./partners/kilma.png";
import hub from './Venue/hub.png'
import trans from "./partners/trans.png";
import fmr from './logos/fmm.png'
import adm from './logos/admf.png'
import eivi from './logos/fews.png'
import bibo from './logos/bibo.png'
import ibal from './logos/ibial.png'
import pim from './logos/pim.png'
import ebu from './logos/ebu.png'
import hifi from './logos/hifi.png'
import uni from './logos/unii.png'
import mari from './logos/marm.png'

import tetr from './logos/tetr.png'
import amen from './logos/amibi.png'
import djen from './logos/djen.png'
import klub from './logos/klub.png'
import las from './logos/lasd.png'
import somi from './logos/Somi.png'

import gifi from "./gifi.gif";
import Speaker from "./Speaker";
import { Grid } from "@mui/material";
import Sponser from "./Sponsor";
import Navbar from "../component/Navbar";

import ice from "./ice.jpg";
import Gallery from './Gallary'

import MediaPartner from './MediaPartnerMobile'


import Footer from "./FooterMobile";


import ebuone from './logos/eb1.jpeg'
import ebutwo from './logos/eb2.jpg'
import ebutwoo from './logos/bu1.jpeg'
import ebutwooo from './logos/bu2.jpeg'

import huone from './logos/hu1.jpg'
import hutwo from './logos/hu3.jpg'

import Program from './Tabs'
import sex from './logos/sex.png'
import vet from './logos/vetse.png'

import ImageGallery from "react-image-gallery";
// import stylesheet if you're not already using CSS @import
import "react-image-gallery/styles/css/image-gallery.css";

const images = [
  {
    original: ebutwo,
    thumbnail: ebutwo,
  },
  {
    original: ebuone,
    thumbnail: ebuone,
  },
  
 
];

const imagess = [
  {
    original: ebutwoo,
    thumbnail: ebutwoo,
  },
  {
    original: ebutwooo,
    thumbnail: ebutwooo,
  },
 
];


const imagesss = [
  {
    original: huone,
    thumbnail: huone,
  },
  {
    original:  hutwo,
    thumbnail:  hutwo,
  },
  
  
 
];
















const HeroSectionMobile = () => {
  return (
    <div className="desktop_hero_bg">
    <Navbar />
      <Grid>
        <Grid item md={12} lg={12} sm={12} xs={12} className="flex">
          <img src={ibizamb} style={{ width: "100%" }} />
        </Grid>
      </Grid>
      <br />      <br />
      <div className="container"> 
      <Grid container spacing={2}>
      <Grid item  md={3} lg={3} sm={3} xs={3} className="centerpartner">
        <a href="https://www.eivissa.es/portal/index.php/ca/" target="_blank">   <img src={eivi} style={{ width: "35%" }} /></a>
   
        </Grid>
        <Grid item  md={3} lg={3} sm={3} xs={3} className="centerpartner">
        <a href="https://www.uib.eu/" target="_blank">   <img src={uni} style={{ width: "80%" , position:"relative"  }} /></a>
   
        </Grid>
        <Grid item  md={3} lg={3} sm={3} xs={3} className="centerpartner">
        <a href="https://www.pimeef.com/" target="_blank">   <img src={pim} style={{ width: "90%" , position:"relative"  }} /></a>
   
        </Grid>
        <Grid item  md={3} lg={3} sm={3} xs={3} className="centerpartner">
        <a href="https://admefy.com/login" target="_blank">   <img src={adm} style={{ width: "60%" }} /></a>
   
        </Grid>
        <Grid item  md={3} lg={3} sm={3} xs={3} className="centerpartner">
        <a href="https://terrenauto.com/" target="_blank">   <img src={tetr} style={{ width: "90%" , position:"relative" , top:"-20px" }} /></a>
   
        </Grid>
 <Grid item md={3} lg={3} sm={3} xs={3}  className="centerpartner">  <> <a href="https://www.barcelo.com/en-ww/" target="_blank">   <img src={bar} style={{ width: "60%", position:"relative" , top:"-20px"  }} /></a></>
 
 </Grid>



 <Grid item md={3} lg={3} sm={3} xs={3}  className="centerpartner">  <> <a href="https://www.barcelo.com/en-es/occidental-ibiza/" target="_blank">   <img src={oc} style={{ width: "80%", position:"relative" , top:"-20px"  }} /></a></>
 
 </Grid>
   
 <Grid item  md={3} lg={3} sm={3} xs={3} className="centerpartner">
        <a href="https://ibizabotanicobiotecnologico.com/" target="_blank">   <img src={bibo} style={{ width: "40%", position:"relative" , top:"-20px"  }} /></a>
   
        </Grid>
    
       
      
      
        <Grid item  md={3} lg={3} sm={3} xs={3} className="centerpartner">
        <a href="https://www.fmarimayans.com/" target="_blank">   <img src={mari} style={{ width: "80%" , position:"relative" , bottom:"30px" }} /></a>
   
        </Grid>

        <Grid item  md={3} lg={3} sm={3} xs={3} className="centerpartner">
        <a href="https://www.amnesia.es/" target="_blank">   <img src={amen} style={{ width: "70%" , position:"relative" , bottom:"25px" }} /></a>
   
        </Grid>
        <Grid item  md={3} lg={3} sm={3} xs={3} className="centerpartner">
        <a href="https://djenerates.com/" target="_blank">   <img src={djen} style={{ width: "70%" , position:"relative" , bottom:"25px"  }} /></a>
   
        </Grid>



        <Grid item  md={3} lg={3} sm={3} xs={3} className="centerpartner">
        <a href="https://klubcoin.net/" target="_blank">   <img src={klub} style={{ width: "50%" , position:"relative" , bottom:"20px" }} /></a>
   
        </Grid>

        <Grid item  md={3} lg={3} sm={3} xs={3} className="centerpartner">
        <a href="https://hiflycatering.com/en/" target="_blank">   <img src={hifi} style={{ width: "90%" , position:"relative" , bottom:"25px"  }} /></a>
   
        </Grid>

        <Grid item  md={3} lg={3} sm={3} xs={3} className="centerpartner">
        <a href="https://fmr-brands.com/" target="_blank">   <img src={fmr} style={{ width: "70%" , position:"relative" , bottom:"25px"  }} /></a>
   
        </Grid>




        
        <Grid item  md={3} lg={3} sm={3} xs={3} className="centerpartner">
        <a href="https://ibizaloe.com/en/" target="_blank">   <img src={ibal} style={{ width: "80%" , position:"relative" , bottom:"25px"  }} /></a>
   
        </Grid>




        <Grid item  md={3} lg={3} sm={3} xs={3} className="centerpartner">
        <a href="https://lasdalias.es/" target="_blank">   <img src={las} style={{ width: "80%" , position:"relative" , bottom:"25px" }} /></a>
   
        </Grid>



        <Grid item  md={3} lg={3} sm={3} xs={3} className="centerpartner">
        <a href="https://www.instagram.com/sexycologic_art_wines/" target="_blank">   <img src={sex} style={{ width: "50%" , position:"relative" , bottom:"25px" }} /></a>
   
        </Grid>



        <Grid item  md={3} lg={3} sm={3} xs={3} className="centerpartner">
        <a href="https://vettoniaseguridad.com/" target="_blank">   <img src={vet} style={{ width: "50%" , position:"relative" , bottom:"25px" }} /></a>
   
        </Grid>

        <Grid item  md={3} lg={3} sm={3} xs={3} className="centerpartner">
        <a href="https://somiartibiza.com/" target="_blank">   <img src={somi} style={{ width: "70%" , position:"relative" , bottom:"25px" }} /></a>
   
        </Grid>
      </Grid>
      
      
    
        <br /><Grid container>
        <Grid item md={12} lg={12} sm={12} xs={12} className="flex">
        <br />  <br />
        <br />
        <h1 className="parahead1"> <span className="maketextprominent"> FREE ADMISSION  </span>  <br /><br />Join us in Ibiza for enlightening insights and inspirational stories.  Secure your spot now – limited availability</h1>

        <br />
        <br />
       
        </Grid> <br /> <br />  <br />
        <br />
        <Grid item md={12} lg={12} sm={12} xs={12} className="flex">  <br />
        <br />
        <br /> <br />  <br />  <br />
        <br />
        <br />
        <a href="#registration" className="btn-grad "> Register now !</a>  
     

</Grid>
      </Grid></div>
     
      <Grid item md={12} lg={12} sm={12} xs={12} className="flex"> 
       
  
        <a href="./Agenda" className="btn-grad "> Agenda</a>  

</Grid>

      <br />  <br />
        <br />
      <div className="container paddodpar">  <br />
        <br />  <Grid container>
        <Grid item md={12} lg={12} sm={12} xs={12} >
              <h1 className="parahead1">Welcome to Ibiza NXT 3.0<br /><br />Heading toward a purpose-driven Web3 innovation journey</h1>
       <br/>
    
       <p className="underparahead1 ">Ibiza NXT is back with its third edition, exploring the intersection of purpose and technology. 
Discover how Web3 and blockchain innovation can elevate our lives. 
Besides focusing on Web3 mass adoption status and sharing remarkable trends about NFTs, the Metaverse, DeFi, and DAOs, we will delve deep into blockchain-AI connections, asset tokenization, new sustainability synergies, and the concept of 'Blockchain Cities.'
Moreover, we will highlight the role of women in technology, provide insights into tech careers, and reflect on the challenges that arise when innovation is not aligned with purpose and visionary goals.
</p>
<br/>
<img src={gifi} style={{ width: "100%" , borderRadius:"20px" }} />
        </Grid>
      </Grid>
      
      </div>
    
<div className="container" >
<hr className="hrline"></hr>
<Grid container spacing={2}>
<Grid item md={12} lg={12} sm={12} xs={12} > <br />
      <br />
<h1 className="parahead1">Exploring industry trends & Human stories on the future-oriented island</h1>
 <br/>
  </Grid>

  <Grid item md={6} lg={6} sm={12} xs={12}> <br/>
  <img src={ice} style={{ width: "100%" , borderRadius:"20px" }} />

  </Grid>

  
  <Grid item md={6} lg={6} sm={12} xs={12}>

  <p className="underparahead1 paddodpar">
    
  Ibiza, the globally renowned 'White Island,' once again takes center stage at our conference. 
It not only embodies history, culture, and lifestyle but is also a forward-looking place, embarking on a transformation into a 'blockchain island' with the aim of fostering connectivity and sustainability within its vibrant community
Ibiza NXT 2023 features a unique format, bringing together bright minds from both local and international communities, alongside researchers, artists, and innovators actively shaping the future. 
The morning sessions host thought-provoking panels, inspiring talks, and the unveiling of cutting-edge innovations and real-use cases in the Web3 space.
In the late afternoons, we transition into intimate interviews, delving into the personal narratives and motivations of tech enthusiasts. Moreover, we provide a platform for artistic expression, featuring NFT art exhibitions, DJ sets, live music performances, and more.


</p>
<br />
      <br />
  </Grid>
</Grid>
</div>
      <br />

      <div className="container ">  <br />
      <br /><hr className="hrline"></hr> <br />
      <br /> <Grid container>
      <Grid item md={12} lg={12} sm={12} xs={12} id='partner'>
      <h1 className="parahead1part">PARTNERS</h1>
       <br/>
        </Grid>
      </Grid>
      
      
      <Grid container spacing={2}>
      <Grid item  md={3} lg={3} sm={3} xs={3} className="centerpartner">
        <a href="https://www.travala.com/" target="_blank">   <img src={travela} style={{ width: "90%" , position:"relative" , top:"-6px"  }} /></a>
       
        </Grid>
        <Grid item  md={3} lg={3} sm={3} xs={3}  className="centerpartner">
        <a href="https://www.dex.art/" target="_blank">   <img src={dexa} style={{ width: "80%", position:"relative" , top:"-10px"  }} /></a>
        </Grid>


    
  
  <Grid item md={3} lg={3} sm={3} xs={3}  className="centerpartner">
  <a href="https://www.fiolegal.com/" target="_blank">   <img src={fio} style={{ width: "100%", position:"relative" , top:"-8px"  }} /></a>
  </Grid>


  <Grid item md={3} lg={3} sm={3} xs={3}  className="centerpartner">
  <a href="https://ethermail.io/" target="_blank">   <img src={ethe} style={{ width: "90%", position:"relative" , top:"-11px"  }} /></a>
  </Grid>

  <Grid item md={3} lg={3} sm={3} xs={3}  className="centerpartner">
  <a href="https://folks.finance/" target="_blank">   <img src={folk} style={{ width: "90%", position:"relative" , top:"-4px"  }} /></a>
  </Grid>
  <Grid item md={3} lg={3} sm={3} xs={3}  className="centerpartner">  <> <a href="https://www.klimadao.finance" target="_blank">   <img src={kilma} style={{ width: "90%", position:"relative" , top:"-4px"   }} /></a></>
 
 </Grid>
 
  <Grid item md={3} lg={3} sm={3} xs={3}  className="centerpartner">  <> <a href="https://www.dtravel.com/" target="_blank">   <img src={dtra} style={{ width: "80%", position:"relative" , top:"-6px"  }} /></a></>
 
 </Grid>


 <Grid item md={3} lg={3} sm={3} xs={3}  className="centerpartner">  <> <a href="https://quadrans.io/" target="_blank">   <img src={quad} style={{ width: "90%", position:"relative" , top:"-6px"  }} /></a></>
 
 </Grid>
 <Grid item md={3} lg={3} sm={3} xs={3}  className="centerpartner">  <> <a href="" target="_blank">   <img src={trans} style={{ width: "90%", position:"relative" , top:"5px"  }} /></a></>
 
 </Grid>
 <Grid item md={3} lg={3} sm={3} xs={3}  className="centerpartner">  <> <a href="https://www.asesoriamadavi.com/" target="_blank">   <img src={mada} style={{ width: "60%", position:"relative" , top:"5px"  }} /></a></>
 
 </Grid>

 <Grid item md={3} lg={3} sm={3} xs={3}  className="centerpartner">  <> <a href="https://elysiumcc.com/" target="_blank">   <img src={ele} style={{ width: "70%", position:"relative" , top:"5px"   }} /></a></>
 
 </Grid>
 <Grid item md={3} lg={3} sm={3} xs={3}  className="centerpartner">  <> <a href="" target="_blank">   <img src={xre} style={{ width: "85%", position:"relative" , top:"5px"  }} /></a></>
 
 </Grid>
 
      </Grid>
      <br />
      <hr className="hrline"></hr>
      </div>

      <br />

<MediaPartner/>

      <br />

      <div className="container "><hr className="hrline"></hr> 
 <Grid container>
<Grid item md={12} lg={12} sm={12} xs={12} id='mediapartner'><br />
<h1 className="parahead1part">VENUES</h1>
 <br/> <br/>
  </Grid>
</Grid>


<Grid container spacing={2}>
<Grid item md={12} lg={12} sm={12} xs={12} className="centerpartner">
  <a href="https://www.yourhubibiza.com/" target="_blank">   <img src={hub} style={{ width: "30%" }} /></a>

  </Grid>
  <Grid item md={12} lg={12} sm={12} xs={12} id='mediapartner'>  <ImageGallery items={imagesss} /> <br/>
  </Grid>
  <Grid item md={12} lg={12} sm={12} xs={12} id='mediapartner'><br />
<h1 className="parahead1l">Baluarte de Sant Pere</h1>
 <br/> 
  </Grid>


 <Grid item md={12} lg={12} sm={12} xs={12} id='mediapartner'>  <ImageGallery items={imagess} />
  </Grid>
  <Grid item md={12} lg={12} sm={12} xs={12} className="centerpartner">
  <a href="" target="_blank">   <img src={ebu} style={{ width: "50%" , position:"relative" , bottom:"1px" }} /></a>

  </Grid>
  <br />

<Grid item md={12} lg={12} sm={12} xs={12} id='mediapartner'>  <ImageGallery items={images} />
</Grid>
 
</Grid>

<br />
      <br />

</div>

<br />

<div className="container" id='speaker'>
<hr className="hrline"></hr> <br />
      <br />
  <Speaker/> <br />
      <br />
</div>

<br />
<div className="container" id="registration">
<hr className="hrline"></hr>
<Sponser />
</div>
 


<div className="container "> <hr className="hrline"></hr>

   
 <Grid container>
<Grid item md={12} lg={12} sm={12} xs={12} >
<br />
      <br />
<h1 className="parahead1">Relive the best moments from past editions of Ibiza NXT</h1>
 <br/>
 <br/>

 
  </Grid>
</Grid>

<Gallery/>


</div>

<br />

<Footer />

    </div>
  );
};

export default HeroSectionMobile;
