import React from "react";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import HeroSectionMobile from "../component/AgendaDone";
import HeroSectionDesktop from "../component/AgendaDone";
import './hml.scss'

function Garden() {
  const circles = [];
  for (let i = 1; i <= 100; i++) {
    circles.push(
      <div key={i} className="circle-container">
        <div className="circle"></div>
        
      </div>
    );
  }
  const theme = useTheme();
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  return (
    <div className="garden">
      {/* THIS JAVASCRIPT CODE IS FOR Header of the Park Page*/}
    
      {/* <Slider /> */}
      {!smallScreen && (
        <div style={{ backgroundColor: " " }}>
         
        <HeroSectionDesktop />
         
        </div>
      )}
      {/* THIS JAVASCRIPT CODE IS FOR the Slider used for the Desktop View when you press on CustomSlider with ctrl and right arrow you move towards the Slider Page Desktop View*/}
      {smallScreen && (
        <div>
        
          <HeroSectionMobile />

       
        </div>
      )}
      {/* THIS JAVASCRIPT CODE IS FOR the Slider used for the Mobile View when you press on MobileSlider with ctrl and right arrow you move towards the Slider Page Mobile View*/}
    </div>
    
  );
}

export default Garden;
