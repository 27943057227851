import React from "react";
import "./HeroSectionDesktop.css";



import { Grid } from "@mui/material";



import dao from "./MediaPartners/dao.png";
import cmint from "./MediaPartners/cmint.png";
import icoho from "./MediaPartners/icoho.png";
import bitme from "./MediaPartners/Bitmedia.png";
import block from "./MediaPartners/block.jpg";
import blockt from "./MediaPartners/blockt.png";
import ftt from "./MediaPartners/fttt.png";
import newcry from "./MediaPartners/newcry.png";
import geek from "./MediaPartners/geek.png";
import coin from "./MediaPartners/coin.png";
import coinfea from "./MediaPartners/coinfea.png";
import coingapa from "./MediaPartners/coingape.png";
import coinpedia from "./MediaPartners/coinp.png";
import coinspeaker from "./MediaPartners/coinspeaker.png";
import coint from "./MediaPartners/coint.png";
import cryptoevent from "./MediaPartners/cryptoevent.png";
import cryptonews from "./MediaPartners/cnew.png";
import blope from "./MediaPartners/blope.png";
import there from "./MediaPartners/there.png";
import ultra from "./MediaPartners/ultram.png";
import uto from "./MediaPartners/uto.webp";
import btc from "./MediaPartners/btc.png";
import newsbtc from "./MediaPartners/newsbtc.png";
import crypp from "./MediaPartners/crypp.png";
import coinb from "./MediaPartners/coinb.png";



function MediaPartner() {
  return (
    <div><div className="container "> 
    <Grid container>
   <Grid item md={12} lg={12} sm={12} xs={12} id='mediapartner'>
   <div data-aos="fade-up"><h1 className="parahead1part">MEDIA PARTNERS</h1></div>
   
    <br/>
     </Grid>
   </Grid>
   
   
   <Grid container spacing={2}>
   <Grid item md={3} lg={3} sm={3} xs={3}  className="centerpartner"><div data-aos="zoom-in-up">     <a href="https://cointelegraph.com/" target="_blank">   <img src={coint} style={{ width: "90%", position:"relative" , top:"10px"   }} /></a></div>

     </Grid>
   <Grid item md={3} lg={3} sm={3} xs={3} className="centerpartner"><div data-aos="zoom-in-up">     <a href=" https://www.bankless.community/" target="_blank">   <img src={dao} style={{ width: "60%" }} /></a></div>

   
     </Grid>
     <Grid item md={3} lg={3} sm={3} xs={3}  className="centerpartner"><div data-aos="zoom-in-up">     <a href="https://u.today/" target="_blank">   <img src={uto} style={{ width: "80%", position:"relative" , top:"-7px"   }} /></a></div>

     </Grid>
     <Grid item md={3} lg={3} sm={3} xs={3}  className="centerpartner"><div data-aos="zoom-in-up">     <a href=" https://en.cryptonomist.ch" target="_blank">   <img src={cmint} style={{ width: "100%", position:"relative" , top:"-7px"  }} /></a></div>

     </Grid>
   
   
     <Grid item md={3} lg={3} sm={3} xs={3}  className="centerpartner"><div data-aos="zoom-in-up">     <a href="https://ibizaloe.com/" target="_blank">   <img src={icoho} style={{ width: "70%", position:"relative" , top:"-7px"  }} /></a></div>

     </Grid>
   
     <Grid item md={3} lg={3} sm={3} xs={3}  className="centerpartner"><div data-aos="zoom-in-up">     <a href="https://bitmedia.io/" target="_blank">   <img src={bitme} style={{ width: "70%", position:"relative" , top:"-7px"  }} /></a></div>

     </Grid>
     
     <Grid item md={3} lg={3} sm={3} xs={3}  className="centerpartner"><div data-aos="zoom-in-up">     <a href="https://blocktides.com" target="_blank">   <img src={block} style={{ width: "40%", position:"relative" , top:"-7px"  }} /></a></div>

     </Grid>
   
   
   
   
     <Grid item md={3} lg={3} sm={3} xs={3}  className="centerpartner"><div data-aos="zoom-in-up">     <a href="https://blocktelegraph.io/" target="_blank">   <img src={blockt} style={{ width: "100%", position:"relative" , top:"-7px"  }} /></a></div>

     </Grid>
     <Grid item md={3} lg={3} sm={3} xs={3}  className="centerpartner"><div data-aos="zoom-in-up">     <a href="https://financialtechtimes.com/" target="_blank">   <img src={ftt} style={{ width: "70%", position:"relative" , top:"-20px"  }} /></a></div>

     </Grid>
     <Grid item md={3} lg={3} sm={3} xs={3}  className="centerpartner"><div data-aos="zoom-in-up">     <a href="https://thenewscrypto.com/" target="_blank">   <img src={newcry} style={{ width: "100%", position:"relative" , top:"-20px"  }} /></a></div>

     </Grid>
    
   
     <Grid item md={3} lg={3} sm={3} xs={3}  className="centerpartner"><div data-aos="zoom-in-up">     <a href="https://coinedition.com/" target="_blank">   <img src={coin} style={{ width: "80%", position:"relative" , top:"-20px"   }} /></a></div>

     </Grid>
   
   
     <Grid item md={3} lg={3} sm={3} xs={3}  className="centerpartner"><div data-aos="zoom-in-up">     <a href="https://coinfea.com/" target="_blank">   <img src={coinfea} style={{ width: "80%", position:"relative" , top:"-20px"   }} /></a></div>

     </Grid>
     <Grid item md={3} lg={3} sm={3} xs={3}  className="centerpartner"><div data-aos="zoom-in-up">     <a href="https://www.geekmetaverse.com/" target="_blank">   <img src={geek} style={{ width: "100%", position:"relative" , top:"-7px"  }} /></a></div>



     </Grid>
     <Grid item md={3} lg={3} sm={3} xs={3}  className="centerpartner"><div data-aos="zoom-in-up">     <a href="https://coingape.com/" target="_blank">   <img src={coingapa} style={{ width: "80%", position:"relative" , top:"10px"   }} /></a></div>


 
     </Grid>
   
  
   
     <Grid item md={3} lg={3} sm={3} xs={3}  className="centerpartner"><div data-aos="zoom-in-up">  <a href="https://www.coinspeaker.com/" target="_blank">   <img src={coinspeaker} style={{ width: "70%", position:"relative" , top:"10px"   }} /></a></div>
   

     </Grid>
   
   
     <Grid item md={3} lg={3} sm={3} xs={3}  className="centerpartner"><div data-aos="zoom-in-up">     <a href="https://cryptoevents.global/" target="_blank">   <img src={cryptoevent} style={{ width: "90%", position:"relative" , top:"10px"   }} /></a></div>

    
     </Grid>
   
   
     <Grid item md={3} lg={3} sm={3} xs={3}  className="centerpartner"><div data-aos="zoom-in-up"><a href="https://cryptonews.com/" target="_blank">   <img src={cryptonews} style={{ width: "90%", position:"relative" , top:"10px"   }} /></a></div>
     
     </Grid>
   
   
     
     <Grid item md={3} lg={3} sm={3} xs={3}  className="centerpartner"><div data-aos="zoom-in-up"><a href="https://ultramining.com/" target="_blank">   <img src={ultra} style={{ width: "80%", position:"relative" , top:"10px"   }} /></a></div>
     
     </Grid>
     <Grid item md={3} lg={3} sm={3} xs={3}  className="centerpartner"><div data-aos="zoom-in-up">     <a href="https://coinpedia.org/" target="_blank">   <img src={coinpedia} style={{ width: "50%", position:"relative" , top:"10px"   }} /></a></div>


</Grid>
     <Grid item md={3} lg={3} sm={3} xs={3}  className="centerpartner"><div data-aos="zoom-in-up"><a href="https://www.thecoinrepublic.com/" target="_blank">   <img src={there} style={{ width: "80%", position:"relative" , top:"10px"   }} /></a></div>
     
     </Grid>
     <Grid item md={3} lg={3} sm={3} xs={3}  className="centerpartner"><div data-aos="zoom-in-up">     <a href="https://theblockopedia.com/" target="_blank">   <img src={blope} style={{ width: "60%", position:"relative" , top:"10px"   }} /></a></div>

     </Grid>
   
   
   
   
     <Grid item md={3} lg={3} sm={3} xs={3}  className="centerpartner"><div data-aos="zoom-in-up"> <a href="https://bitcoinist.com/" target="_blank">   <img src={btc} style={{ width: "80%", position:"relative" , top:"10px"   }} /></a></div>
    
     </Grid>
   
     <Grid item md={3} lg={3} sm={3} xs={3}  className="centerpartner"><div data-aos="zoom-in-up"><a href="https://www.newsbtc.com/" target="_blank">   <img src={newsbtc} style={{ width: "80%", position:"relative" , top:"10px"   }} /></a></div>
     <br/>  <br/><br/> <br/><br/> <br/><br/> 
     </Grid>
   
     <Grid item md={3} lg={3} sm={3} xs={3}  className="centerpartner"><div data-aos="zoom-in-up"><a href="https://www.cryptopolitan.com/" target="_blank">   <img src={crypp} style={{ width: "80%", position:"relative" , top:"10px"   }} /></a></div>
     
     </Grid>
     <Grid item md={3} lg={3} sm={3} xs={3}  className="centerpartner"><div data-aos="zoom-in-up"><a href="https://coinbold.io/" target="_blank">   <img src={coinb} style={{ width: "80%", position:"relative" , top:"40px"   }} /></a></div>
     
     </Grid>
   </Grid>
   
   <br />
         <br />
   
   
   
   </div></div>
  )
}

export default MediaPartner