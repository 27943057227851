import React, { useState, useEffect } from "react";
import "./App.css";
import Home from "./screens/Home";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./Theme";
import Agenda from './screens/Agenda'


function App() {
  const [isLoading, setIsLoading] = useState(true);

  // Simulate a delay (e.g., fetching data, initializing resources)
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false); // Set loading to false after the delay
    }, 10000); // Adjust the delay as needed
  }, []);

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Router>
        
            <Routes>
              <Route path="/" exact={true} element={<Home />} />
              <Route path="/Agenda" exact={true} element={<Agenda />} />
              
            </Routes>
      
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
