import * as React from 'react';
import PropTypes from 'prop-types';
import hub from './Venue/hub.png'
import ebu from './logos/ebu.png'
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import loc from './locas.png'
import { Grid } from "@mui/material";


import som from './Somi.png'
import am from './amibi.png'
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function FullWidthTabs() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <>
      <div className='container flex' >





        <a
          onClick={() => handleChange(null, 0)}
          style={{
            backgroundColor: value === 0 ? theme.palette.secondary.main : 'transparent',
            color: value === 0 ? 'white' : 'black',
          }}
          className="btn-grady"
        >
4 de octubre
        </a>
        <a
          onClick={() => handleChange(null, 1)}
          style={{
            backgroundColor: value === 1 ? theme.palette.secondary.main : 'transparent',
            color: value === 1 ? 'white' : 'black',
          }}
          className="btn-grady"
        >
   5 de octubre
        </a>

        <a
          onClick={() => handleChange(null, 2)}
          style={{
            backgroundColor: value === 2 ? theme.palette.secondary.main : 'transparent',
            color: value === 2 ? 'white' : 'black',
          }}
          className="btn-grady"
        >
      6 de octubre
        </a>
      </div>
     
        <TabPanel value={value} index={0} dir={theme.direction}>
                    
        <div class="container">
        <Grid container>
      <Grid item md={12} lg={12} sm={12} xs={12} id='partner' className='centeralldiv'>
      <>     <br/>  <img src={hub} style={{width:"150px"}}/> </>
      <br/>  <br/>  <br/>
        </Grid>

        <Grid item md={12} lg={12} sm={12} xs={12} id='partner' className='centeralldiv'>
      <a  href='https://www.google.com/maps/place/Ca+Na+Palava/@38.9587059,1.4438436,17z/data=!3m1!4b1!4m6!3m5!1s0x129940b5608e66c7:0xbc18f468c44ba18a!8m2!3d38.9587059!4d1.4438436!16s%2Fg%2F11fyxd5vsl?entry=ttu' target='_blank' >     <br/>  <img src={loc} style={{width:"120px"}} className='vert-move'/> </a>
       <br/>
        </Grid>
      </Grid>
      <br/>  <br/>  <br/>
   
  <div class="timeline">
    <div class="timeline-month">
    Miércoles 4 - 10 - 23   
    </div>
    
    <div class="timeline-section">
      <div class="timeline-date">
      10.30
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            
            <div class="box-content">
              <a class="" style={{fontSize:"20px" , color:"#fff"}}> ENTRADA / DESAYUNO</a>
    
            </div>
        
          </div>
        </div>
    

      </div>

    </div>

    <div class="timeline-section">
      <div class="timeline-date">
      11.00 -11.05 - Introducción
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-pencil text-info" aria-hidden="true"></i> Ponentes
            </div>
            <div class="box-content">
             
              <div class="box-item">Cripto Willy<span style={{color:"#00ccc5"}}> - Presentador / Moderador</span></div>
              <div class="box-item">Ana Lucía Lara <span style={{color:"#00ccc5"}}> - Presentadora / Moderadora  </span> </div>
              <div class="box-item">Carmen Pi <span style={{color:"#00ccc5"}}> - Presentadora / Moderadora </span></div>

            </div>
          
          </div>
        </div>
      
        <div class="col-sm-6">  <br/>
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-tasks text-primary" aria-hidden="true"></i>Título

            </div>
            <div class="box-footer">
            Bienvenidos a Ibiza NXT 2023: Encaminándonos hacia un viaje de
innovación con propósito
          
           </div>
          </div>
        </div>
    
      </div>
 

    </div>
    <div class="timeline-section">
      <div class="timeline-date">
      11:05 - 11:40 - CHARLA
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-pencil text-info" aria-hidden="true"></i> Ponentes
            </div>
            <div class="box-content">
             
              <div class="box-item">Alek Hannessian<span style={{color:"#00ccc5"}}> - Head of Partnerships & Sales, Ethermail  </span></div>
             

            </div>
          
          </div>
        </div>
        <div class="col-sm-6"> <br/>
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-tasks text-primary" aria-hidden="true"></i>Título

            </div>
            <div class="box-footer">
            Cerrando la brecha de comunicación en el espacio descentralizado. Conexiones efectivas entre usuarios y proyectos
          
           </div>
          </div>
        </div>
    
      </div>
 

    </div>
    <div class="timeline-section">
      <div class="timeline-date">
      11:40 - 12:15 - CHARLA
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-pencil text-info" aria-hidden="true"></i> Ponentes
            </div>
            <div class="box-content">
             
              <div class="box-item">Rosa Pericàs Gornals <span style={{color:"#00ccc5"}}> -  Universidad de las Islas Baleares  </span></div>
             

            </div>
          
          </div>
        </div>
        <div class="col-sm-6"> <br/>
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-tasks text-primary" aria-hidden="true"></i>Título

            </div>
            <div class="box-footer">
            Hacia una comunicación descentralizada: notificaciones confidenciales certificadas
          
           </div>
          </div>
        </div>
    
      </div>
 

    </div>
    <div class="timeline-section">
      <div class="timeline-date">
      12:15 - 12:50 - CHARLA 
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-pencil text-info" aria-hidden="true"></i> Ponentes
            </div>
            <div class="box-content">
             
              <div class="box-item">Zeno Auersperg <span style={{color:"#00ccc5"}}> -   Head of Economic Research, Folks Finance</span></div>
             

            </div>
          
          </div>
        </div>
        <div class="col-sm-6"> <br/>
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-tasks text-primary" aria-hidden="true"></i>Título

            </div>
            <div class="box-footer">
            La revolución de las finanzas: la descentralización como base para construir un sistema más democrático
          
           </div>
          </div>
        </div>
    
      </div>
 

    </div>
    <div class="timeline-section">
      <div class="timeline-date">
      12:50 - 13.30 - CHARLA 
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-pencil text-info" aria-hidden="true"></i> Ponentes
            </div>
            <div class="box-content">
             
              <div class="box-item">Alex Nascimento <span style={{color:"#00ccc5"}}> - Managing director 7CC investments / faculty and co-founder Blockchain at UCL</span></div>
             

            </div>
          
          </div>
        </div>
        <div class="col-sm-6"> <br/>
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-tasks text-primary" aria-hidden="true"></i>Título

            </div>
            <div class="box-footer">
            Tecnologías de fronteras (blockchain, IA/VR, IoT): revolucionando negocios y servicios financieros
          
           </div>
          </div>
        </div>
    
      </div>
 

    </div>
    <div class="timeline-section">
      <div class="timeline-date">
      13:30 - 14.05 - Panel 
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-pencil text-info" aria-hidden="true"></i> Ponentes
            </div>
            <div class="box-content">
             
              <div class="box-item">Jonathan Napolitano<span style={{color:"#00ccc5"}}> -   Founder & CTO of X-R3AL / Researcher.   </span></div>
              <div class="box-item"> Luiza Castro Rey <span style={{color:"#00ccc5"}}> -  Partner and  Head of Business and Web3, FIO Legal solutions</span></div>

            </div>
          
          </div>
        </div>
        <div class="col-sm-6"> <br/>
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-tasks text-primary" aria-hidden="true"></i>Título

            </div>
            <div class="box-footer">
            La tokenización de activos como herramienta para afrontar los grandes desafíos de la actualidad 
          
           </div>
          </div>
        </div>
    
      </div>
 

    </div>
    <div class="timeline-section">
      <div class="timeline-date">
      14:05 - 14:35 - CHARLA
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-pencil text-info" aria-hidden="true"></i> Ponentes
            </div>
            <div class="box-content">
             
              <div class="box-item"> Bob Reid<span style={{color:"#00ccc5"}}> -  CEO Everest.org </span></div>
             

            </div>
          
          </div>
        </div>
        <div class="col-sm-6"> <br/>
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-tasks text-primary" aria-hidden="true"></i>Título

            </div>
            <div class="box-footer">
            Hacia una adopción masiva de finanzas descentralizadas: verificación de la identidad digital
           </div>
          </div>
        </div>
    
      </div>
 

    </div>
    <div class="timeline-section">
      <div class="timeline-date">
      14:35
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            
            <div class="box-content">
              <a class="" style={{fontSize:"20px" , color:"#fff"}}>ALMUERZO NETWORKING 
</a>
    
            </div>
        
          </div>
        </div>
    

      </div>

    </div>

  </div>
</div>
<div class="container">
<Grid container>
      <Grid item md={12} lg={12} sm={12} xs={12} id='partner' className='centeralldiv'>
      <>     <br/>  <br/>  <br/> <h1 class="parahead1l">Baluarte de Sant Pere</h1> </>
       <br/>  <br/>  <br/>
        </Grid>

        <Grid item md={12} lg={12} sm={12} xs={12} id='partner' className='centeralldiv'>
      <a href='https://www.google.com/maps/dir//Pla%C3%A7a+del+Sol,+9,+07800+Eivissa,+Illes+Balears/@38.9080289,1.4330566,19z/data=!4m8!4m7!1m0!1m5!1m1!1s0x129946b078b178eb:0xf7ba92d4e5a28b82!2m2!1d1.4337038!2d38.9080579?entry=ttu'  target='_blank'>     <br/>  <img src={loc} style={{width:"120px"}}  className='vert-move'/> </a>
       <br/>
        </Grid>
      </Grid>
      <br/>  <br/>  <br/>
   
  <div class="timeline">
    <div class="timeline-month">
    Wednesday 4 - 10 - 23   
    </div>
    
  

    <div class="timeline-section">
      <div class="timeline-date">
      18.30 - 19.10 
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-pencil text-info" aria-hidden="true"></i> Ponentes
            </div>
            <div class="box-content">
              <div class="box-item">Rubén Sousa<span style={{color:"#00ccc5"}}> - Concejal de Ciudad Inteligente, Transporte Urbano y Gestión de Fondos y Proyectos Europeos. Ajuntament d’Eivissa</span></div>
            </div>
          
          </div>
        </div>
      
        <div class="col-sm-6">  <br/>
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-tasks text-primary" aria-hidden="true"></i>Título

            </div>
            <div class="box-footer">
            ENTREVISTA
          
           </div>
          </div>
        </div>
    
      </div>
 

    </div>
    <div class="timeline-section">
      <div class="timeline-date">
      19.10 - 19.50
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-pencil text-info" aria-hidden="true"></i> Ponentes
            </div>
            <div class="box-content">
             
              <div class="box-item">Bruce Sterling<span style={{color:"#00ccc5"}}> -  Novelista y fundador del movimiento cyberpunk </span></div>
             

            </div>
          
          </div>
        </div>
        <div class="col-sm-6"> <br/>
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-tasks text-primary" aria-hidden="true"></i>Título

            </div>
            <div class="box-footer">
            ENTREVISTA
          
           </div>
          </div>
        </div>
    
      </div>
 

    </div>
    <div class="timeline-section">
      <div class="timeline-date">
      19.50 - 20.30
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-pencil text-info" aria-hidden="true"></i> Ponentes
            </div>
            <div class="box-content">
             
              <div class="box-item">Léa Leuzinger<span style={{color:"#00ccc5"}}> -Paleontóloga y Especialista en Fauna en Bibo, Ibiza Botanico Biotecnológico</span></div>
             

            </div>
          
          </div>
        </div>
        <div class="col-sm-6"> <br/>
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-tasks text-primary" aria-hidden="true"></i>Título

            </div>
            <div class="box-footer">
            ENTREVISTA 
          
           </div>
          </div>
        </div>
    
      </div>
 

    </div>

    <div class="timeline-section">
      <div class="timeline-date">
      A partir de las 20.30h
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            
            <div class="box-content">
              <a class="" style={{fontSize:"20px" , color:"#fff"}}>Exposición de arte NFT, música y actuaciones en vivo</a>
    
            </div>
        
          </div>
        </div>
    

      </div>

    </div>

  </div>
</div>
        </TabPanel>


        <TabPanel value={value} index={1} dir={theme.direction}>
   
        <div class="container">
        <Grid container>
      <Grid item md={12} lg={12} sm={12} xs={12} id='partner' className='centeralldiv'>
      <>     <br/>  <img src={hub} style={{width:"150px"}}/> </>
      <br/>  <br/>  <br/>
        </Grid>

        <Grid item md={12} lg={12} sm={12} xs={12} id='partner' className='centeralldiv'>
      <a  href='https://www.google.com/maps/place/Ca+Na+Palava/@38.9587059,1.4438436,17z/data=!3m1!4b1!4m6!3m5!1s0x129940b5608e66c7:0xbc18f468c44ba18a!8m2!3d38.9587059!4d1.4438436!16s%2Fg%2F11fyxd5vsl?entry=ttu' target='_blank' >     <br/>  <img src={loc} style={{width:"120px"}}  className='vert-move'/> </a>
       <br/>
        </Grid>
      </Grid>
      <br/>  <br/>  <br/>
   
  <div class="timeline">
    <div class="timeline-month">
    
Jueves 5 - 10 - 23   
    </div>
    
    <div class="timeline-section">
      <div class="timeline-date">
      10.30
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            
            <div class="box-content">
              <a class="" style={{fontSize:"20px" , color:"#fff"}}>ENTRADA / DESAYUNO</a>
    
            </div>
        
          </div>
        </div>
    

      </div>

    </div>

    <div class="timeline-section">
      <div class="timeline-date">
      11.00 - 11.30 - CHARLA
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-pencil text-info" aria-hidden="true"></i> Ponentes
            </div>
            <div class="box-content">
             
              <div class="box-item">Andrew Bonneau <span style={{color:"#00ccc5"}}> - Head of partnerships, Klima DAO  </span></div>
           

            </div>
          
          </div>
        </div>
      
        <div class="col-sm-6">  <br/>
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-tasks text-primary" aria-hidden="true"></i>Título

            </div>
            <div class="box-footer">
            Sostenibilidad en acción: finanzas con carácter climático 
           </div>
          </div>
        </div>
    
      </div>
 

    </div>
    <div class="timeline-section">
      <div class="timeline-date">
      11.30 - 12.00 - Panel
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-pencil text-info" aria-hidden="true"></i> Ponentes
            </div>
            <div class="box-content">
             
              <div class="box-item">Javier Gonzaled Granado<span style={{color:"#00ccc5"}}> - Notario   </span></div>
             

            </div>
          
          </div>
        </div>
        <div class="col-sm-6"> <br/>
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-tasks text-primary" aria-hidden="true"></i>Título

            </div>
            <div class="box-footer">
            La figura del notario en las transacciones con cripotomonedas 
          
           </div>
          </div>
        </div>
    
      </div>
 

    </div>
    <div class="timeline-section">
      <div class="timeline-date">
      12.00 - 12:30 - Panel
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-pencil text-info" aria-hidden="true"></i> Ponentes
            </div>
            <div class="box-content">
             
              <div class="box-item">Eva Cano <span style={{color:"#00ccc5"}}> -   Técnico del departamento de dinamización de PIMEEF     </span></div>
             
              <div class="box-item">Davide Costa <span style={{color:"#00ccc5"}}> - cofundador Quadrans Foundation 
    </span></div>
            </div>
          
          </div>
        </div>
        <div class="col-sm-6"> <br/>
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-tasks text-primary" aria-hidden="true"></i>Título

            </div>
            <div class="box-footer">
            Preservando la excelencia local: cómo la blockchain apoya los productos locales
          
           </div>
          </div>
        </div>
    
      </div>
 

    </div>
    <div class="timeline-section">
      <div class="timeline-date">
      12:30 - 13:00 - CHARLA
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-pencil text-info" aria-hidden="true"></i> Ponentes
            </div>
            <div class="box-content">
             
              <div class="box-item">Sam Wellalge<span style={{color:"#00ccc5"}}> -  Founder and Principal Recruiter - WorkInCrypto Global </span></div>
             

            </div>
          
          </div>
        </div>
        <div class="col-sm-6"> <br/>
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-tasks text-primary" aria-hidden="true"></i>Título

            </div>
            <div class="box-footer">
            Construir una trayectoria profesional sostenible en la Web3 y sector Tecnologico: un Mapa hacia el éxito
          
           </div>
          </div>
        </div>
    
      </div>
 

    </div>
    <div class="timeline-section">
      <div class="timeline-date">
      13.00 - 13.30 - CHARLA 
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-pencil text-info" aria-hidden="true"></i> Ponentes
            </div>
            <div class="box-content">
             
              <div class="box-item">Chris Biele -  AKA NF
Thinker <span style={{color:"#00ccc5"}}>  - Bankless DAO core contributor   </span></div>
             
              <div class="box-item"> Reinis Kalnins <span style={{color:"#00ccc5"}}> -  Bankless DAO contributor and coordinator </span></div>
            </div>
          
          </div>
        </div>
        <div class="col-sm-6"> <br/>
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-tasks text-primary" aria-hidden="true"></i>Título

            </div>
            <div class="box-footer">
            Desarrollando proyectos Web3: recompensar tu comunidad
          
           </div>
          </div>
        </div>
    
      </div>
 

    </div>
    <div class="timeline-section">
      <div class="timeline-date">
      13.30 - 14:00 - Panel 
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-pencil text-info" aria-hidden="true"></i> Ponentes
            </div>
            <div class="box-content">
             
              <div class="box-item">Cynthia Huang<span style={{color:"#00ccc5"}}> -  CEO of Dtravel </span></div>
<div class="box-item">Laura Perea<span style={{color:"#00ccc5"}}> -CX strategist and Guest Experience Manager, Barcelo hotel Group</span></div>

            </div>
          
          </div>
        </div>
        <div class="col-sm-6"> <br/>
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-tasks text-primary" aria-hidden="true"></i>Título

            </div>
            <div class="box-footer">
            El Futuro del Turismo: Impacto e influencia de blockchain y tecnologías emergentes 
          
           </div>
          </div>
        </div>
    
      </div>
 

    </div>
    <div class="timeline-section">
      <div class="timeline-date">
      14.00 - 14:30 - CHARLA
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-pencil text-info" aria-hidden="true"></i> Ponentes
            </div>
            <div class="box-content">
             
              <div class="box-item">Helena Rong<span style={{color:"#00ccc5"}}> -  PhD Researcher, Columbia University  </span></div>
             

            </div>
          
          </div>
        </div>
        <div class="col-sm-6"> <br/>
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-tasks text-primary" aria-hidden="true"></i>Título

            </div>
            <div class="box-footer">
            De los community Tokens a las criptomonedas vinculadas a ciudades 

          
           </div>
          </div>
        </div>
    
      </div>
 

    </div>
    <div class="timeline-section">
      <div class="timeline-date">
      14:30
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            
            <div class="box-content">
              <a class="" style={{fontSize:"20px" , color:"#fff"}}>ALMUERZO NETWORKING </a>
    
            </div>
        
          </div>
        </div>
    

      </div>

    </div>

  </div>
</div>
<div class="container">
<Grid container>
      <Grid item md={12} lg={12} sm={12} xs={12} id='partner' className='centeralldiv'>
      <>     <br/>  <br/>  <br/> <h1 class="parahead1l">Baluarte de Sant Pere</h1> </>
       <br/>  <br/>  <br/>
        </Grid>

        <Grid item md={12} lg={12} sm={12} xs={12} id='partner' className='centeralldiv'>
      <a href='https://www.google.com/maps/dir//Pla%C3%A7a+del+Sol,+9,+07800+Eivissa,+Illes+Balears/@38.9080289,1.4330566,19z/data=!4m8!4m7!1m0!1m5!1m1!1s0x129946b078b178eb:0xf7ba92d4e5a28b82!2m2!1d1.4337038!2d38.9080579?entry=ttu'  target='_blank'>     <br/>  <img src={loc} style={{width:"120px"}}  className='vert-move'/> </a>
       <br/>
        </Grid>
      </Grid>
      <br/>  <br/>  <br/>
   
  <div class="timeline">
    <div class="timeline-month">
   
Jueves 5 - 10 - 23   
    </div>
    
  

    <div class="timeline-section">
      <div class="timeline-date">
      18.30 - 19.10
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-pencil text-info" aria-hidden="true"></i> Ponentes
            </div>
            <div class="box-content">
              <div class="box-item">Dr Francisco José Perales<span style={{color:"#00ccc5"}}> - Catedrático y Director de la Unidad de Innovación en Videojuegos e Inteligencia Artificial, Universidad de las Islas Baleares </span></div>
            </div>
          
          </div>
        </div>
      
        <div class="col-sm-6">  <br/>
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-tasks text-primary" aria-hidden="true"></i>Título

            </div>
            <div class="box-footer">
            ENTREVISTA
          
           </div>
          </div>
        </div>
    
      </div>
 

    </div>
    <div class="timeline-section">
      <div class="timeline-date">
      19.10 - 19.50 
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-pencil text-info" aria-hidden="true"></i> Ponentes
            </div>
            <div class="box-content">
             
              <div class="box-item">Jasmina Tešanović<span style={{color:"#00ccc5"}}> -  Escritora y activista</span></div>
             

            </div>
          
          </div>
        </div>
        <div class="col-sm-6"> <br/>
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-tasks text-primary" aria-hidden="true"></i>Título

            </div>
            <div class="box-footer">
            ENTREVISTA
          
           </div>
          </div>
        </div>
    
      </div>
 

    </div>
    <div class="timeline-section">
      <div class="timeline-date">
      19.50 - 20.30 
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-pencil text-info" aria-hidden="true"></i> Ponentes
            </div>
            <div class="box-content">
             
              <div class="box-item">Alen Valdivia<span style={{color:"#00ccc5"}}> - Fundador Transcendence Platform </span></div>
             

            </div>
          
          </div>
        </div>
        <div class="col-sm-6"> <br/>
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-tasks text-primary" aria-hidden="true"></i>Título

            </div>
            <div class="box-footer">
            ENTREVISTA 
          
           </div>
          </div>
        </div>
    
      </div>
 

    </div>

    <div class="timeline-section">
      <div class="timeline-date">
      A partir de las 20.30h
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            
            <div class="box-content">
              <a class="" style={{fontSize:"20px" , color:"#fff"}}>Exposición de arte NFT, música y actuaciones en vivo</a>
    
            </div>
        
          </div>
        </div>
    

      </div>

    </div>

  </div>
</div>
        </TabPanel>


        <TabPanel value={value} index={2} dir={theme.direction}>
   
        <div class="container">
        <Grid container>
      <Grid item md={12} lg={12} sm={12} xs={12} id='partner' className='centeralldiv'>
      <>     <br/>  <img src={hub} style={{width:"150px"}}/> </>
      <br/>  <br/>  <br/>
        </Grid>

        <Grid item md={12} lg={12} sm={12} xs={12} id='partner' className='centeralldiv'>
      <a  href='https://www.google.com/maps/place/Ca+Na+Palava/@38.9587059,1.4438436,17z/data=!3m1!4b1!4m6!3m5!1s0x129940b5608e66c7:0xbc18f468c44ba18a!8m2!3d38.9587059!4d1.4438436!16s%2Fg%2F11fyxd5vsl?entry=ttu' target='_blank' >     <br/>  <img src={loc} style={{width:"120px"}}  className='vert-move'/> </a>
       <br/>
        </Grid>
      </Grid>
      <br/>  <br/>  <br/>
   
  <div class="timeline">
    <div class="timeline-month">
    
Viernes 6 - 10 - 23   
    </div>
    
    <div class="timeline-section">
      <div class="timeline-date">
      10.30
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            
            <div class="box-content">
              <a class="" style={{fontSize:"20px" , color:"#fff"}}>ENTRADA / DESAYUNO</a>
    
            </div>
        
          </div>
        </div>
    

      </div>

    </div>

    <div class="timeline-section">
      <div class="timeline-date">
      11.00 - 11.30 - PANEL
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-pencil text-info" aria-hidden="true"></i> Ponentes
            </div>
            <div class="box-content">
             
            <div class="box-item"> Jessica Surendorff <span style={{color:"#00ccc5"}}> - Project manager and business development, Tokentraxx</span></div>
              <div class="box-item">Sarah Main  <span style={{color:"#00ccc5"}}> - Artista  multidisciplinar</span></div>


            
            </div>
          
          </div>
        </div>
      
        <div class="col-sm-6">  <br/>
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-tasks text-primary" aria-hidden="true"></i>Título

            </div>
            <div class="box-footer">
            El Futuro de la música: contenido generado por IA y la descentralización
           </div>
          </div>
        </div>
    
      </div>
 

    </div>
    <div class="timeline-section">
      <div class="timeline-date">
      11.30 - 12.00 - CHARLA
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-pencil text-info" aria-hidden="true"></i> Ponentes
            </div>
            <div class="box-content">
             
              <div class="box-item">Jurgis Didžiulis (aka Jurgis DID)<span style={{color:"#00ccc5"}}> - Divulgador, Músico y Activista </span></div>
             

            </div>
          
          </div>
        </div>
        <div class="col-sm-6"> <br/>
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-tasks text-primary" aria-hidden="true"></i>Título

            </div>
            <div class="box-footer">
            Aprovechando la conexión humana en un mundo cada vez más digital
          
           </div>
          </div>
        </div>
    
      </div>
 

    </div>
    <div class="timeline-section">
      <div class="timeline-date">
      12.00 - 12:30 - Panel
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-pencil text-info" aria-hidden="true"></i> Ponentes
            </div>
            <div class="box-content">
             
              <div class="box-item">VIOLETA M. VALCHEVA  <span style={{color:"#00ccc5"}}> - 
           Fundadora de NFEST  </span></div>
             
              <div class="box-item">  Laura Viana<span style={{color:"#00ccc5"}}> -  
              Abogada de Fio Legal solutions   </span></div>
            </div>
          
          </div>
        </div>
        <div class="col-sm-6"> <br/>
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-tasks text-primary" aria-hidden="true"></i>Título

            </div>
            <div class="box-footer">
            Nuevos horizontes en la creación de contenido: IA, Web3 y Tokenización. Oportunidades y riesgos legales


          
           </div>
          </div>
        </div>
    
      </div>
 

    </div>
    <div class="timeline-section">
      <div class="timeline-date">
      12:30 - 13:00 - Panel
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-pencil text-info" aria-hidden="true"></i> Ponentes
            </div>
            <div class="box-content">
             
              <div class="box-item">Tommy D <span style={{color:"#00ccc5"}}> - Founder and creator of TokenTraxx.  </span></div>
              <div class="box-item"> Arnold Travieso  <span style={{color:"#00ccc5"}}> -  Founder & CEO Crypto Música Records  </span></div>

            </div>
          
          </div>
        </div>
        <div class="col-sm-6"> <br/>
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-tasks text-primary" aria-hidden="true"></i>Título

            </div>
            <div class="box-footer">
            Web3 y la música: nuevas formas de contacto con los fans
          
           </div>
          </div>
        </div>
    
      </div>
 

    </div>
    <div class="timeline-section">
      <div class="timeline-date">
      13.00 - 13.50 - Panel
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-pencil text-info" aria-hidden="true"></i> Ponentes
            </div>
            <div class="box-content">
             
              <div class="box-item">Anatoli Ille<span style={{color:"#00ccc5"}}> - Cofundador de DEXART Metaverso </span></div>
             
              <div class="box-item"> Anton Elston  <span style={{color:"#00ccc5"}}> -  Cofundador de DEXART Metaverso    </span></div>
              <div class="box-item"> Patrick Scheire  <span style={{color:"#00ccc5"}}> - Partner de biza Token  </span></div>
            </div>
          
          </div>
        </div>
        <div class="col-sm-6"> <br/>
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-tasks text-primary" aria-hidden="true"></i>Título

            </div>
            <div class="box-footer">
            Presentación IbizaMeta: la esencia de la 'isla blanca' aterriza en el Metaverso
          
           </div>
          </div>
        </div>
    
      </div>
 

    </div>
    <div class="timeline-section">
      <div class="timeline-date">
      13.50 - 14:20 - TALK
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-pencil text-info" aria-hidden="true"></i> Ponentes
            </div>
            <div class="box-content">
             
            <div class="box-item">Santiago Vich <span style={{color:"#00ccc5"}}> - Jefe del Departamento de Desarrollo y Promoción, Escuela de Art Ibiza         </span></div>
              <div class="box-item">Vitorino Osorio  <span style={{color:"#00ccc5"}}> - Coordinador TIC y profesor de Medios Informáticos, Escuela de Art Ibiza  </span> </div>
              <div class="box-item">Manel Ortí<span style={{color:"#00ccc5"}}> - efe de Departamento de Forja y Ebanistería y profesor de Volumen, Escuela de Art Ibiza  </span></div>
             

            </div>
          
          </div>
        </div>
        <div class="col-sm-6"> <br/>
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-tasks text-primary" aria-hidden="true"></i>Título

            </div>
            <div class="box-footer">
          
            Un FabLab en la Escula de Arte de Ibiza
          
           </div>
          </div>
        </div>
    
      </div>
 

    </div>
    <div class="timeline-section">
      <div class="timeline-date">
      14.20 - 14:30 - TALK
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-pencil text-info" aria-hidden="true"></i> Ponentes
            </div>
            <div class="box-content">
             
            <div class="box-item">Cripto Willy<span style={{color:"#00ccc5"}}> - Presentador / Moderador </span></div>
              <div class="box-item">Ana Lucía Lara <span style={{color:"#00ccc5"}}> -  Presentadora / Moderadora  </span> </div>
              <div class="box-item">Carmen Pi <span style={{color:"#00ccc5"}}> - Presentadora / Moderadora </span></div>
             

            </div>
          
          </div>
        </div>
        <div class="col-sm-6"> <br/>
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-tasks text-primary" aria-hidden="true"></i>Título

            </div>
            <div class="box-footer">
          
            CIERRE DE LAS SESIONES DE LA MAÑANA - Saludos Finales
          
           </div>
          </div>
        </div>
    
      </div>
 

    </div>
    <div class="timeline-section">
      <div class="timeline-date">
      14:30
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            
            <div class="box-content">
              <a class="" style={{fontSize:"20px" , color:"#fff"}}>
              ALMUERZO NETWORKING</a>
    
            </div>
        
          </div>
        </div>
    

      </div>

    </div>

  </div>
</div>
<div class="container">
<Grid container>
      <Grid item md={12} lg={12} sm={12} xs={12} id='partner' className='centeralldiv'>
      <>     <br/>  <br/>  <br/> <img src={ebu} style={{width:"150px"}}/>  </>
       <br/>  <br/>  <br/>
        </Grid>

        <Grid item md={12} lg={12} sm={12} xs={12} id='partner' className='centeralldiv'>
      <a href='https://www.google.com/maps/place/Sociedad+Cultural+Ebusus/@38.9092149,1.4329932,15z/data=!4m6!3m5!1s0x129947eab4e1372f:0x6d00316efcb3c5ea!8m2!3d38.9092149!4d1.4329932!16s%2Fg%2F11s8qssk6n?entry=ttu'  target='_blank'>     <br/>  <img src={loc} style={{width:"120px"}}  className='vert-move'/> </a>
       <br/>
        </Grid>
      </Grid>
      <br/>  <br/>  <br/>
   
  <div class="timeline">
    <div class="timeline-month">
   
Viernes 6 - 10 - 23   
    </div>
    
  

    <div class="timeline-section">
      <div class="timeline-date">
      18.30 - 19.10 
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-pencil text-info" aria-hidden="true"></i> Ponentes
            </div>
            <div class="box-content">
              <div class="box-item">Sarah Main<span style={{color:"#00ccc5"}}> - Artista multicisciplinar  </span></div>
            </div>
          
          </div>
        </div>
      
        <div class="col-sm-6">  <br/>
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-tasks text-primary" aria-hidden="true"></i>Título

            </div>
            <div class="box-footer">
            ENTREVISTA
          
           </div>
          </div>
        </div>
    
      </div>
 

    </div>
    <div class="timeline-section">
      <div class="timeline-date">
      19.10 - 19.50 
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-pencil text-info" aria-hidden="true"></i> Ponentes
            </div>
            <div class="box-content">
             
              <div class="box-item">Cripto Willy<span style={{color:"#00ccc5"}}> - Educador y cryptoincluencer</span></div>
             

            </div>
          
          </div>
        </div>
        <div class="col-sm-6"> <br/>
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-tasks text-primary" aria-hidden="true"></i>Título

            </div>
            <div class="box-footer">
            ENTREVISTA
          
           </div>
          </div>
        </div>
    
      </div>
 

    </div>
    <div class="timeline-section">
      <div class="timeline-date">
      19.50 - 20.30 
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-pencil text-info" aria-hidden="true"></i> Ponentes
            </div>
            <div class="box-content">
             
              <div class="box-item">Jordi Gómez <span style={{color:"#00ccc5"}}> - Fotógrafo y artista</span></div>
             

            </div>
          
          </div>
        </div>
        <div class="col-sm-6"> <br/>
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-tasks text-primary" aria-hidden="true"></i>Título

            </div>
            <div class="box-footer">
            ENTREVISTA 
          
           </div>
          </div>
        </div>
    
      </div>
 

    </div>

    <div class="timeline-section">
      <div class="timeline-date">
      20:30 
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            
            <div class="box-content">
              <a class="" style={{fontSize:"20px" , color:"#fff"}}>CLOSING LATE AFTERNOON SESSIONS</a>
    
            </div>
        
          </div>
        </div>
    

      </div>

    </div>

  </div>
</div>

<div class="container">
     
      <Grid container>
      <Grid item md={12} lg={12} sm={12} xs={12} id='partner' className='centeralldiv'>
      <>     <br/>  <br/>  <br/> <img src={som} style={{width:"150px"}}/>  </>
       <br/>  <br/>  <br/>
        </Grid>

        <Grid item md={12} lg={12} sm={12} xs={12} id='partner' className='centeralldiv'>
      <a href='https://www.google.com/maps/place/Somiart/@38.9822326,1.5331835,15z/data=!4m6!3m5!1s0x1299154dda5cbf91:0xebcf7021ec3ed0c3!8m2!3d38.9822326!4d1.5331835!16s%2Fg%2F1q5bkqxg9?entry=ttu'  target='_blank'>     <br/>  <img src={loc} style={{width:"120px"}}  className='vert-move'/> </a>
       <br/>
        </Grid>
      </Grid>
      <br/>  <br/>  <br/>
  <div class="timeline">
    <div class="timeline-month">
    Viernes 6 - 10 - 23   
    </div>
    
  

    <div class="timeline-section">
      <div class="timeline-date">
      9 PM en adelante
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
           
            <div class="box-content">
              <div class="box-item">Side event<span style={{color:"#00ccc5"}}>   </span></div>
            </div>
          
          </div>
        </div>
      
      
    
      </div>
 

    </div>
   

  </div>
</div>













<div class="container">
     
      <Grid container>
      <Grid item md={12} lg={12} sm={12} xs={12} id='partner' className='centeralldiv'>
      <>     <br/>  <br/>  <br/> <img src={am} style={{width:"150px"}}/>  </>
       <br/>  <br/>  <br/>
        </Grid>

        <Grid item md={12} lg={12} sm={12} xs={12} id='partner' className='centeralldiv'>
      <a href='https://www.google.com/maps/place/Amnesia+Ibiza/@38.9476661,1.4080028,15z/data=!4m2!3m1!1s0x0:0xaf57624bad153856?sa=X&ved=2ahUKEwi31OTKqtiBAxU8gv0HHYygAkAQ_BJ6BAhAEAA&ved=2ahUKEwi31OTKqtiBAxU8gv0HHYygAkAQ_BJ6BAhcEAg'  target='_blank'>     <br/>  <img src={loc} style={{width:"120px"}}  className='vert-move'/> </a>
       <br/>
        </Grid>
      </Grid>
      <br/>  <br/>  <br/>
  <div class="timeline">
    <div class="timeline-month">
    Viernes 6 - 10 - 23   
    </div>
    
  

    <div class="timeline-section">
      <div class="timeline-date">
      12 AM en adelante
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
           
            <div class="box-content">
              <div class="box-item">Side event<span style={{color:"#00ccc5"}}>   </span></div>
            </div>
          
          </div>
        </div>
      
      
    
      </div>
 

    </div>
   

  </div>
</div>

   </TabPanel>
    </>
  );
}
