import React, { useEffect } from "react";
import Aos from "aos";
import "./HeroSectionDesktop.css";
import "aos/dist/aos.css";
import ebuone from './logos/eb1.jpeg'
import ebutwo from './logos/eb2.jpg'
import ebutwoo from './logos/bu1.jpeg'
import ebutwooo from './logos/bu2.jpeg'
import huone from './logos/hu1.jpg'
import hutwo from './logos/hu3.jpg'
import { Grid } from "@mui/material";
import Navbar from "../component/Navbar";
import Footer from "./Footer";

// import stylesheet if you're not already using CSS @import
import "react-image-gallery/styles/css/image-gallery.css";


import Program from './Tabs'


const images = [
  {
    original: ebutwo,
    thumbnail: ebutwo,
  },
  {
    original: ebuone,
    thumbnail: ebuone,
  },
  
 
];

const imagess = [
  {
    original: ebutwoo,
    thumbnail: ebutwoo,
  },
  {
    original: ebutwooo,
    thumbnail: ebutwooo,
  },
 
];


const imagesss = [
  {
    original: huone,
    thumbnail: huone,
  },
  {
    original:  hutwo,
    thumbnail:  hutwo,
  },
  
  
 
];


const HeroSectionMobile = () => {
  useEffect(function () {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <div className="desktop_hero_bg">
      <Navbar />
    
    <br />      <br />
    

      <Grid container>
      <Grid item md={12} lg={12} sm={12} xs={12} id='partner'>
      <div data-aos="fade-up">    <h1 className="parahead1part">AGENDA 2023</h1>  </div>
       <br/>
        </Grid>
      </Grid>
      <br /> <br />
     <Program/>
      <br />  
        <br />
   
<Footer />
 
    </div>
  );
};

export default HeroSectionMobile;
