import * as React from 'react';
import PropTypes from 'prop-types';

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import a1 from './2021/1.jpg'
import a2 from './2021/2.jpg'
import a3 from './2021/3.jpg'
import a4 from './2021/4.jpg'
import a5 from './2021/5.jpg'
import a6 from './2021/6.jpg'
import a7 from './2021/7.jpg'
import a8 from './2021/8.jpg'
import a9 from './2021/9.jpg'
import a10 from './2021/10.jpg'
import a11 from './2021/11.jpg'
import a12 from './2021/12.jpg'
import a13 from './2021/13.jpg'
import a14 from './2021/14.jpg'
import a15 from './2021/15.jpg'
import a16 from './2021/16.jpg'
import a17 from './2021/17.jpg'
import a18 from './2021/18.jpg'
import a19 from './2021/19.jpg'
import a20 from './2021/20.jpg'
import a21 from './2021/21.jpg'
import a22 from './2021/22.jpg'
import a23 from './2021/23.jpg'
import a24 from './2021/24.jpg'



import p1 from './2022/1.jpg'
import p2 from './2022/2.jpg'
import p3 from './2022/3.jpg'
import p4 from './2022/4.jpg'
import p5 from './2022/5.jpg'
import p6 from './2022/6.jpg'
import p7 from './2022/7.jpg'
import p9 from './2022/9.jpg'
import p10 from './2022/10.jpg'
import p11 from './2022/11.jpg'
import p12 from './2022/12.jpg'
import p13 from './2022/13.jpg'
import p14 from './2022/14.jpg'
import p15 from './2022/15.jpg'
import p16 from './2022/16.jpg'
import p17 from './2022/17.jpg'
import p18 from './2022/18.jpg'

import { Grid } from "@mui/material";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function FullWidthTabs() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <>
      <div className='container flex' >

         
        <a
          onClick={() => handleChange(null, 0)}
          style={{
            backgroundColor: value === 0 ? theme.palette.secondary.main : 'transparent',
            color: value === 0 ? 'white' : 'black',
          }}
          className="btn-grad "
        >
          2021
        </a>
        <a
          onClick={() => handleChange(null, 1)}
          style={{
            backgroundColor: value === 1 ? theme.palette.secondary.main : 'transparent',
            color: value === 1 ? 'white' : 'black',
          }}
          className="btn-grad "
        >
          2022
        </a>
      </div>
     
        <TabPanel value={value} index={0} dir={theme.direction}>
         
<Grid container spacing={2}>


<Grid item md={4} lg={4} sm={6} xs={6} className="centerpartner">
     <img src={a1} style={{ width: "100%" , borderRadius:"10px" }}  />

  </Grid>
  <Grid item md={4} lg={4} sm={6} xs={6} className="centerpartner">
     <img src={a2} style={{ width: "100%" , borderRadius:"10px" }}  />

  </Grid>
  <Grid item md={4} lg={4} sm={6} xs={6} className="centerpartner">
     <img src={a3} style={{ width: "100%" , borderRadius:"10px" }}  />
  </Grid>

  <Grid item md={4} lg={4} sm={6} xs={6} className="centerpartner">
     <img src={a4} style={{ width: "100%" , borderRadius:"10px" }}  />

  </Grid>


  <Grid item md={4} lg={4} sm={6} xs={6} className="centerpartner">
     <img src={a5} style={{ width: "100%" , borderRadius:"10px" }}  />

  </Grid>
  <Grid item md={4} lg={4} sm={6} xs={6} className="centerpartner">
     <img src={a6} style={{ width: "100%" , borderRadius:"10px" }}  />

  </Grid>

  <Grid item md={4} lg={4} sm={6} xs={6} className="centerpartner">
     <img src={a7} style={{ width: "100%" , borderRadius:"10px" }}  />

  </Grid>
  <Grid item md={4} lg={4} sm={6} xs={6} className="centerpartner">
     <img src={a8} style={{ width: "100%" , borderRadius:"10px" }}  />

  </Grid>
  <Grid item md={4} lg={4} sm={6} xs={6} className="centerpartner">
     <img src={a9} style={{ width: "100%" , borderRadius:"10px" }}  />

  </Grid>
  <Grid item md={4} lg={4} sm={6} xs={6} className="centerpartner">
     <img src={a10} style={{ width: "100%" , borderRadius:"10px" }}  />

  </Grid>
  <Grid item md={4} lg={4} sm={6} xs={6} className="centerpartner">
     <img src={a11} style={{ width: "100%" , borderRadius:"10px" }}  />

  </Grid>
  <Grid item md={4} lg={4} sm={6} xs={6} className="centerpartner">
     <img src={a12} style={{ width: "100%" , borderRadius:"10px" }}  />

  </Grid>
  <Grid item md={4} lg={4} sm={6} xs={6} className="centerpartner">
     <img src={a13} style={{ width: "100%" , borderRadius:"10px" }}  />

  </Grid>
  <Grid item md={4} lg={4} sm={6} xs={6} className="centerpartner">
     <img src={a14} style={{ width: "100%" , borderRadius:"10px" }}  />

  </Grid>
  <Grid item md={4} lg={4} sm={6} xs={6} className="centerpartner">
     <img src={a15} style={{ width: "100%" , borderRadius:"10px" }}  />

  </Grid>
  <Grid item md={4} lg={4} sm={6} xs={6} className="centerpartner">
     <img src={a16} style={{ width: "100%" , borderRadius:"10px" }}  />

  </Grid>
  <Grid item md={4} lg={4} sm={6} xs={6} className="centerpartner">
     <img src={a17} style={{ width: "100%" , borderRadius:"10px" }}  />

  </Grid>
  <Grid item md={4} lg={4} sm={6} xs={6} className="centerpartner">
     <img src={a18} style={{ width: "100%" , borderRadius:"10px" }}  />

  </Grid>
  <Grid item md={4} lg={4} sm={6} xs={6} className="centerpartner">
     <img src={a19} style={{ width: "100%" , borderRadius:"10px" }}  />

  </Grid>
  <Grid item md={4} lg={4} sm={6} xs={6} className="centerpartner">
     <img src={a20} style={{ width: "100%" , borderRadius:"10px" }}  />

  </Grid>
  <Grid item md={4} lg={4} sm={6} xs={6} className="centerpartner">
     <img src={a21} style={{ width: "100%" , borderRadius:"10px" }}  />

  </Grid>
  <Grid item md={4} lg={4} sm={6} xs={6} className="centerpartner">
     <img src={a22} style={{ width: "100%" , borderRadius:"10px" }}  />

  </Grid>
  <Grid item md={4} lg={4} sm={6} xs={6} className="centerpartner">
     <img src={a23} style={{ width: "100%" , borderRadius:"10px" }}  />

  </Grid>
  <Grid item md={4} lg={4} sm={6} xs={6} className="centerpartner">
     <img src={a24} style={{ width: "100%" , borderRadius:"10px" }}  />

  </Grid>
</Grid>

        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
        <Grid container spacing={2}>


<Grid item md={4} lg={4} sm={6} xs={6} className="centerpartner">
     <img src={p1} style={{ width: "100%" , borderRadius:"10px" }}  />

  </Grid>
  <Grid item md={4} lg={4} sm={6} xs={6} className="centerpartner">
     <img src={p2} style={{ width: "100%" , borderRadius:"10px" }}  />

  </Grid>
  <Grid item md={4} lg={4} sm={6} xs={6} className="centerpartner">
     <img src={p3} style={{ width: "100%" , borderRadius:"10px" }}  />
  </Grid>

  <Grid item md={4} lg={4} sm={6} xs={6} className="centerpartner">
     <img src={p4} style={{ width: "100%" , borderRadius:"10px" }}  />

  </Grid>


  <Grid item md={4} lg={4} sm={6} xs={6} className="centerpartner">
     <img src={p5} style={{ width: "100%" , borderRadius:"10px" }}  />

  </Grid>
  <Grid item md={4} lg={4} sm={6} xs={6} className="centerpartner">
     <img src={p6} style={{ width: "100%" , borderRadius:"10px" }}  />

  </Grid>

  <Grid item md={4} lg={4} sm={6} xs={6} className="centerpartner">
     <img src={p7} style={{ width: "100%" , borderRadius:"10px" }}  />

  </Grid>
 
  <Grid item md={4} lg={4} sm={6} xs={6} className="centerpartner">
     <img src={p9} style={{ width: "100%" , borderRadius:"10px" }}  />

  </Grid>
  <Grid item md={4} lg={4} sm={6} xs={6} className="centerpartner">
     <img src={p10} style={{ width: "100%" , borderRadius:"10px" }}  />

  </Grid>
  <Grid item md={4} lg={4} sm={6} xs={6} className="centerpartner">
     <img src={p11} style={{ width: "100%" , borderRadius:"10px" }}  />

  </Grid>
  <Grid item md={4} lg={4} sm={6} xs={6} className="centerpartner">
     <img src={p12} style={{ width: "100%" , borderRadius:"10px" }}  />

  </Grid>
  <Grid item md={4} lg={4} sm={6} xs={6} className="centerpartner">
     <img src={p13} style={{ width: "100%" , borderRadius:"10px" }}  />

  </Grid>
  <Grid item md={4} lg={4} sm={6} xs={6} className="centerpartner">
     <img src={p14} style={{ width: "100%" , borderRadius:"10px" }}  />

  </Grid>
  <Grid item md={4} lg={4} sm={6} xs={6} className="centerpartner">
     <img src={p15} style={{ width: "100%" , borderRadius:"10px" }}  />

  </Grid>
  <Grid item md={4} lg={4} sm={6} xs={6} className="centerpartner">
     <img src={p16} style={{ width: "100%" , borderRadius:"10px" }}  />

  </Grid>
  <Grid item md={4} lg={4} sm={6} xs={6} className="centerpartner">
     <img src={p17} style={{ width: "100%" , borderRadius:"10px" }}  />

  </Grid>
  <Grid item md={4} lg={4} sm={6} xs={6} className="centerpartner">
     <img src={p18} style={{ width: "100%" , borderRadius:"10px" }}  />

  </Grid>
 
  <Grid item md={4} lg={4} sm={6} xs={6} className="centerpartner">
     <img src={a20} style={{ width: "100%" , borderRadius:"10px" }}  />

  </Grid>
 

 
</Grid>

        </TabPanel>
    
    </>
  );
}
