import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import Card from '@mui/material/Card';
import ana from './Speakers/ana.png'
import jonathan from './Speakers/jonathan.jpeg'
import nftthinker from './Speakers/nfthinker.jpeg'
import renis from './Speakers/renis.jpeg'
import sam from './Speakers/sam.jpeg'
import alek from './Speakers/alek.jpg'
import alex from './Speakers/Alex.jpeg'
import bene from './Speakers/bene.JPG'
import bob from './Speakers/bob.jpg'
import helena from './Speakers/helena.jpeg'
import laura from './Speakers/laura.jpg'
import luiza from './Speakers/luiza.jpg'
import cryw from './Speakers/cryw.jpeg'
import sara from './Speakers/sara.jpg'
import vio from './Speakers/vio.jpg'
import cay from './Speakers/cay.jpg'
import david from './Speakers/david.jpeg'
import anat from './Speakers/anat.png'
import anton from './Speakers/anton.png'
import rosa from './Speakers/rosa.jpg'
import jasmi from './Speakers/jasmi.jpg'
import bruce from './Speakers/bruc.jpg'
import jor from './Speakers/jor.png'
import andr from './Speakers/andr.jpg'
import mar from './Speakers/mar.jpg'
import zeno from './Speakers/zeno.jpeg'
import jav from './Speakers/jav.jpg'
import arno from './Speakers/arno.jpg'
import xatt from './Speakers/xatt.png'
import eva from './Speakers/eva.jpg'
import Laura from './Speakers/Laura.jpeg'
import Tommy from './Speakers/tommy.jpeg'
import rub from './Speakers/rub.jpg'
import mari from './Speakers/mari.jpg'
import jessi from './Speakers/jessi.jpg'
import carm from './Speakers/carm.jpeg'
import lea from './Speakers/lea.jpeg'
import jur from './Speakers/jur.jpg'
import fper from './Speakers/fran.jpeg'
import alen from './Speakers/alen.jpeg'
import sant from './Speakers/Sant.jpeg'
import mane from './Speakers/Mane.jpeg'
import vitor from './Speakers/Vitor.jpeg'










import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import Typography from '@mui/material/Typography';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

import  './speaker.css'
// import required modules
import { EffectCoverflow, Pagination, Autoplay } from 'swiper/modules';
import { Grid } from '@mui/material'
function Speaker() {
  return (
    <div>
 <Grid container>
<Grid item md={12} lg={12} sm={12} xs={12} >
<h1 className="parahead1">Meet our speakers and engage in thought-provoking discussions and inspiring conversation</h1>
 <br/>
  </Grid>
  <Swiper
        effect={'coverflow'}
        grabCursor={true}
        spaceBetween={80}
        centeredSlides={true}
        slidesPerView={'auto'}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
    
        modules={[EffectCoverflow, Pagination, Autoplay]} // Add Autoplay module
        className="mySwiper"
        autoplay={{ // Configure autoplay options
          delay: 7000, // 3 seconds delay between slides
          disableOnInteraction: false, // Autoplay continues after user interactions
        }}
      >

        
<SwiperSlide>
        <Card sx={{ maxWidth: 345 }} className='centerallspeaker'>
      <CardMedia
        sx={{ height: 350 }}
        image={carm}
        title="green iguana"
   
      />
      <CardContent>
       <p className='speaker_head'>Carmen Pi Cascales
</p>
       <p className='speaker_para'>Official Host - Journalist and Communicator</p>
      </CardContent>
     
    </Card>
        </SwiperSlide>
          <SwiperSlide>
      <Card sx={{ maxWidth: 345 }} className='centerallspeaker'>
    <CardMedia
      sx={{ height: 350 }}
      image={cryw}
      title="green iguana"
    />
    <CardContent>
     <p className='speaker_head'>Cripto Willy</p>
     <p className='speaker_para'> Official Host - Educator & influencer around Crypto and Digital assets 
</p>
    </CardContent>
   
  </Card>
      </SwiperSlide>
        
          <SwiperSlide>
      <Card sx={{ maxWidth: 345 }} className='centerallspeaker'>
    <CardMedia
      sx={{ height: 350 }}
      image={ana}
      title="green iguana"
    />
    <CardContent>
     <p className='speaker_head'>Ana Lucía Lara</p>
     <p className='speaker_para'> Official Host - (Bankless DAO)
</p>
    </CardContent>
   
  </Card>
      </SwiperSlide>
        <SwiperSlide>
        <Card sx={{ maxWidth: 345 }} className='centerallspeaker'>
      <CardMedia
        sx={{ height: 350 }}
        image={jonathan}
        title="green iguana"
      />
      <CardContent>
       <p className='speaker_head'>Jonathan Napolitano</p>
       <p className='speaker_para'>  Founder X-R3AL / Researcher</p>
      </CardContent>
     
    </Card>
        </SwiperSlide>
        <SwiperSlide>
        <Card sx={{ maxWidth: 345 }} className='centerallspeaker'>
      <CardMedia
        sx={{ height: 350 }}
        image={nftthinker}
        title="green iguana"
      />
      <CardContent>
       <p className='speaker_head'>NF Thinker</p>
       <p className='speaker_para'> Bankless DAO core contributor
</p>
      </CardContent>
     
    </Card>
        </SwiperSlide>



        <SwiperSlide>
        <Card sx={{ maxWidth: 345 }} className='centerallspeaker'>
      <CardMedia
        sx={{ height: 350 }}
        image={renis}
        title="green iguana"
      />
      <CardContent>
       <p className='speaker_head'>Reinis Kalnins
</p>
       <p className='speaker_para'> Bankless DAO contributor and coordinator
</p>
      </CardContent>
     
    </Card>
        </SwiperSlide>



        <SwiperSlide>
        <Card sx={{ maxWidth: 345 }} className='centerallspeaker'>
      <CardMedia
        sx={{ height: 350 }}
        image={sam}
        title="green iguana"
      />
      <CardContent>
       <p className='speaker_head'>Sam Wellalage
</p>
       <p className='speaker_para'> Founder and Principal Recruiter - WorkInCrypto Global 
</p>
      </CardContent>
     
    </Card>
        </SwiperSlide>

        <SwiperSlide>
        <Card sx={{ maxWidth: 345 }} className='centerallspeaker'>
      <CardMedia
        sx={{ height: 350 }}
        image={alek}
        title="green iguana"
      />
      <CardContent>
       <p className='speaker_head'>Alek Hannessian
</p>
       <p className='speaker_para'> Head of Partnerships & Sales at  EtherMail

</p>
      </CardContent>
     
    </Card>
        </SwiperSlide>

        <SwiperSlide>
        <Card sx={{ maxWidth: 345 }} className='centerallspeaker'>
      <CardMedia
        sx={{ height: 350 }}
        image={alex}
        title="green iguana"
      />
      <CardContent>
       <p className='speaker_head'>Alex Nascimento
</p>
       <p className='speaker_para'> Managing director 7CC investments / faculty and co-founder Blockchain at UCLA


</p>
      </CardContent>
     
    </Card>
        </SwiperSlide>







        
        <SwiperSlide>
        <Card sx={{ maxWidth: 345 }} className='centerallspeaker'>
      <CardMedia
        sx={{ height: 350 }}
        image={bob}
        title="green iguana"
      />
      <CardContent>
       <p className='speaker_head'>Bob Reid
</p>
       <p className='speaker_para'> CEO and co-founder of Everest




</p>
      </CardContent>
     
    </Card>
        </SwiperSlide>



 
        <SwiperSlide>
        <Card sx={{ maxWidth: 345 }} className='centerallspeaker'>
      <CardMedia
        sx={{ height: 350 }}
        image={helena}
        title="green iguana"
      />
      <CardContent>
       <p className='speaker_head'>HELENA RONG 
</p>
       <p className='speaker_para'> PhD Researcher at Columbia University




</p>
      </CardContent>
     
    </Card>
        </SwiperSlide>

        <SwiperSlide>
        <Card sx={{ maxWidth: 345 }} className='centerallspeaker'>
      <CardMedia
        sx={{ height: 350 }}
        image={laura}
        title="green iguana"
      />
      <CardContent>
       <p className='speaker_head'>Laura Viana 
</p>
       <p className='speaker_para'> Lawyer at FIO Legal 





</p>
      </CardContent>
     
    </Card>
        </SwiperSlide>

        <SwiperSlide>
        <Card sx={{ maxWidth: 345 }} className='centerallspeaker'>
      <CardMedia
        sx={{ height: 350 }}
        image={luiza}
        title="green iguana"
      />
      <CardContent>
       <p className='speaker_head'>Luiza Castro Rey
</p>
       <p className='speaker_para'> Partner and  Head of Business and Web3 at  FIO Legal 






</p>
      </CardContent>
     
    </Card>
        </SwiperSlide>

 

   
        <SwiperSlide>
        <Card sx={{ maxWidth: 345 }} className='centerallspeaker'>
      <CardMedia
        sx={{ height: 350 }}
        image={sara}
        title="green iguana"
      />
      <CardContent>
       <p className='speaker_head'>Sarah Main
</p>
       <p className='speaker_para'> Multidisciplinary artist 








</p>
      </CardContent>
     
    </Card>
        </SwiperSlide>

        <SwiperSlide>
        <Card sx={{ maxWidth: 345 }} className='centerallspeaker'>
      <CardMedia
        sx={{ height: 350 }}
        image={vio}
        title="green iguana"
      />
      <CardContent>
       <p className='speaker_head'>Violeta M. Valcheva
</p>
       <p className='speaker_para'> Founder of NFEST & Interdisciplinary creator









</p>
      </CardContent>
     
    </Card>
        </SwiperSlide>








        <SwiperSlide>
        <Card sx={{ maxWidth: 345 }} className='centerallspeaker'>
      <CardMedia
        sx={{ height: 350 }}
        image={cay}
        title="green iguana"
      />
      <CardContent>
       <p className='speaker_head'>Cynthia Huang
</p>
       <p className='speaker_para'> CEO of Dtravel









</p>
      </CardContent>
     
    </Card>
        </SwiperSlide>


        <SwiperSlide>
        <Card sx={{ maxWidth: 345 }} className='centerallspeaker'>
      <CardMedia
        sx={{ height: 350 }}
        image={david}
        title="green iguana"
      />
      <CardContent>
       <p className='speaker_head'>David Costa 
</p>
       <p className='speaker_para'> co-founder Quadrans Foundation









</p>
      </CardContent>
     
    </Card>
        </SwiperSlide>



        <SwiperSlide>
        <Card sx={{ maxWidth: 345 }} className='centerallspeaker'>
      <CardMedia
        sx={{ height: 350 }}
        image={anat}
        title="green iguana"
   
      />
      <CardContent>
       <p className='speaker_head'>Anatoli Ille 

</p>
       <p className='speaker_para'> Co-founder of DEXART Metaverse










</p>
      </CardContent>
     
    </Card>
        </SwiperSlide>




        <SwiperSlide>
        <Card sx={{ maxWidth: 345 }} className='centerallspeaker'>
      <CardMedia
        sx={{ height: 350 }}
        image={anton}
        title="green iguana"
   
      />
      <CardContent>
       <p className='speaker_head'>Anton Elston


</p>
       <p className='speaker_para'>Co-founder of the DEXART metaverse










</p>
      </CardContent>
     
    </Card>
        </SwiperSlide>




        <SwiperSlide>
        <Card sx={{ maxWidth: 345 }} className='centerallspeaker'>
      <CardMedia
        sx={{ height: 350 }}
        image={rosa}
        title="green iguana"
   
      />
      <CardContent>
       <p className='speaker_head'>Rosa Pericàs Gornals 


</p>
       <p className='speaker_para'>PhD student at University of the Balearic Islands











</p>
      </CardContent>
     
    </Card>
        </SwiperSlide>



        <SwiperSlide>
        <Card sx={{ maxWidth: 345 }} className='centerallspeaker'>
      <CardMedia
        sx={{ height: 350 }}
        image={jasmi}
        title="green iguana"
   
      />
      <CardContent>
       <p className='speaker_head'>Jasmina Tešanović 


</p>
       <p className='speaker_para'>Author, Activist &  Artist











</p>
      </CardContent>
     
    </Card>
        </SwiperSlide>


        <SwiperSlide>
        <Card sx={{ maxWidth: 345 }} className='centerallspeaker'>
      <CardMedia
        sx={{ height: 350 }}
        image={bruce}
        title="green iguana"
   
      />
      <CardContent>
       <p className='speaker_head'>Bruce Sterling


</p>
       <p className='speaker_para'>Science fiction author












</p>
      </CardContent>
     
    </Card>
        </SwiperSlide>




        <SwiperSlide>
        <Card sx={{ maxWidth: 345 }} className='centerallspeaker'>
      <CardMedia
        sx={{ height: 350 }}
        image={jor}
        title="green iguana"
   
      />
      <CardContent>
       <p className='speaker_head'>Jordi Gómez


</p>
       <p className='speaker_para'>Artist













</p>
      </CardContent>
     
    </Card>
        </SwiperSlide>



        <SwiperSlide>
        <Card sx={{ maxWidth: 345 }} className='centerallspeaker'>
      <CardMedia
        sx={{ height: 350 }}
        image={andr}
        title="green iguana"
   
      />
      <CardContent>
       <p className='speaker_head'>Andrew Bonneau 


</p>
       <p className='speaker_para'>Head of Partnerships,  KlimaDAO 














</p>
      </CardContent>
     
    </Card>
        </SwiperSlide>



        <SwiperSlide>
        <Card sx={{ maxWidth: 345 }} className='centerallspeaker'>
      <CardMedia
        sx={{ height: 350 }}
        image={mar}
        title="green iguana"
   
      />
      <CardContent>
       <p className='speaker_head'>Marco Crotta


</p>
       <p className='speaker_para'>Blockchain Caffè founder & TEDx speaker 















</p>
      </CardContent>
     
    </Card>
        </SwiperSlide>










        <SwiperSlide>
        <Card sx={{ maxWidth: 345 }} className='centerallspeaker'>
      <CardMedia
        sx={{ height: 350 }}
        image={zeno}
        title="green iguana"
   
      />
      <CardContent>
       <p className='speaker_head'>Zeno Auersperg 


</p>
       <p className='speaker_para'>Head of Economic Research, Folks Finance















</p>
      </CardContent>
     
    </Card>
        </SwiperSlide>


        
        <SwiperSlide>
        <Card sx={{ maxWidth: 345 }} className='centerallspeaker'>
      <CardMedia
        sx={{ height: 350 }}
        image={jav}
        title="green iguana"
   
      />
      <CardContent>
       <p className='speaker_head'>Javier González Granado


</p>
       <p className='speaker_para'>Notary 
















</p>
      </CardContent>
     
    </Card>
        </SwiperSlide>





        <SwiperSlide>
        <Card sx={{ maxWidth: 345 }} className='centerallspeaker'>
      <CardMedia
        sx={{ height: 350 }}
        image={arno}
        title="green iguana"
   
      />
      <CardContent>
       <p className='speaker_head'>Arnold Travieso


</p>
       <p className='speaker_para'>Founder & CEO Crypto Música Récords

















</p>
      </CardContent>
     
    </Card>
        </SwiperSlide>


        <SwiperSlide>
        <Card sx={{ maxWidth: 345 }} className='centerallspeaker'>
      <CardMedia
        sx={{ height: 350 }}
        image={xatt}
        title="green iguana"
   
      />
      <CardContent>
       <p className='speaker_head'>Xattan


</p>
       <p className='speaker_para'> Artist 


















</p>
      </CardContent>
     
    </Card>
        </SwiperSlide>



        <SwiperSlide>
        <Card sx={{ maxWidth: 345 }} className='centerallspeaker'>
      <CardMedia
        sx={{ height: 350 }}
        image={eva}
        title="green iguana"
   
      />
      <CardContent>
       <p className='speaker_head'>EVA CANO JIMÉNEZ


</p>
       <p className='speaker_para'> Promotion department technical officer, PIMEEF



















</p>
      </CardContent>
     
    </Card>
        </SwiperSlide>


        <SwiperSlide>
        <Card sx={{ maxWidth: 345 }} className='centerallspeaker'>
      <CardMedia
        sx={{ height: 350 }}
        image={Laura}
        title="green iguana"
   
      />
      <CardContent>
       <p className='speaker_head'>Laura Perea


</p>
       <p className='speaker_para'> Customer Experirnce Strategist. Guest Experience Manager, Barceló Hotel Group



















</p>
      </CardContent>
     
    </Card>
        </SwiperSlide>




        <SwiperSlide>
        <Card sx={{ maxWidth: 345 }} className='centerallspeaker'>
      <CardMedia
        sx={{ height: 350 }}
        image={Tommy}
        title="green iguana"
   
      />
      <CardContent>
       <p className='speaker_head'>Tommy D


</p>
       <p className='speaker_para'> Founder and creator of TokenTraxx



















</p>
      </CardContent>
     
    </Card>
        </SwiperSlide>




        <SwiperSlide>
        <Card sx={{ maxWidth: 345 }} className='centerallspeaker'>
      <CardMedia
        sx={{ height: 350 }}
        image={rub}
        title="green iguana"
   
      />
      <CardContent>
       <p className='speaker_head'>Rubén Sousa Muñoz


</p>
       <p className='speaker_para'>  Councillor for Smart City, Urban Transport, City of Ibiza 



















</p>
      </CardContent>
     
    </Card>
        </SwiperSlide>







        
        <SwiperSlide>
        <Card sx={{ maxWidth: 345 }} className='centerallspeaker'>
      <CardMedia
        sx={{ height: 350 }}
        image={mari}
        title="green iguana"
   
      />
      <CardContent>
       <p className='speaker_head'>Mariana Brandão Carr


</p>
       <p className='speaker_para'>  Head of Business Development, Casperaki




















</p>
      </CardContent>
     
    </Card>
        </SwiperSlide>



        <SwiperSlide>
        <Card sx={{ maxWidth: 345 }} className='centerallspeaker'>
      <CardMedia
        sx={{ height: 350 }}
        image={jessi}
        title="green iguana"
   
      />
      <CardContent>
       <p className='speaker_head'>Jessica Surendorff
</p>
       <p className='speaker_para'>  Project manager and business development, Tokentraxx</p>
      </CardContent>
     
    </Card>
        </SwiperSlide>

        <SwiperSlide>
        <Card sx={{ maxWidth: 345 }} className='centerallspeaker'>
      <CardMedia
        sx={{ height: 350 }}
        image={lea}
        title="green iguana"
   
      />
      <CardContent>
       <p className='speaker_head'>Léa Leuzinger
</p>
       <p className='speaker_para'>  Earth Scientist & Fauna Specialist</p>
      </CardContent>
     
    </Card>
        </SwiperSlide>


        <SwiperSlide>
        <Card sx={{ maxWidth: 345 }} className='centerallspeaker'>
      <CardMedia
        sx={{ height: 350 }}
        image={jur}
        title="green iguana"
   
      />
      <CardContent>
       <p className='speaker_head'> Jurgis Didžiulis (Jurgis DID)
</p>
       <p className='speaker_para'> Speaker, Educator, Musician and Activist
</p>
      </CardContent>
     
    </Card>
        </SwiperSlide>

        
        <SwiperSlide>
        <Card sx={{ maxWidth: 345 }} className='centerallspeaker'>
      <CardMedia
        sx={{ height: 350 }}
        image={fper}
        title="green iguana"
   
      />
      <CardContent>
       <p className='speaker_head'> 
Dr Francisco José Perales 

</p>
       <p className='speaker_para'> Full Professor and Director of the Innovation Unit in Video Games and Artificial Intelligence, University of the Balearic Islands
</p>
      </CardContent>
     
    </Card>
        </SwiperSlide>




             
        <SwiperSlide>
        <Card sx={{ maxWidth: 345 }} className='centerallspeaker'>
      <CardMedia
        sx={{ height: 350 }}
        image={alen}
        title="green iguana"
   
      />
      <CardContent>
       <p className='speaker_head'> 
       Alen Valdivia

</p>
       <p className='speaker_para'> Founder of Transcendence Platform
</p>
      </CardContent>
     
    </Card>
        </SwiperSlide>

















        <SwiperSlide>
        <Card sx={{ maxWidth: 345 }} className='centerallspeaker'>
      <CardMedia
        sx={{ height: 350 }}
        image={sant}
        title="green iguana"
   
      />
      <CardContent>
       <p className='speaker_head'> 
       Santiago Vich 

</p>
       <p className='speaker_para'> Head of Development and Promotion , Ibiza School of Art
</p>
      </CardContent>
     
    </Card>
        </SwiperSlide>




        <SwiperSlide>
        <Card sx={{ maxWidth: 345 }} className='centerallspeaker'>
      <CardMedia
        sx={{ height: 350 }}
        image={mane}
        title="green iguana"
   
      />
      <CardContent>
       <p className='speaker_head'> 
       Manel Ortí 
</p>
       <p className='speaker_para'> Head of Forging and Woodworking, Volume Professor, Ibiza School of Art

</p>
      </CardContent>
     
    </Card>
        </SwiperSlide>


        <SwiperSlide>
        <Card sx={{ maxWidth: 345 }} className='centerallspeaker'>
      <CardMedia
        sx={{ height: 350 }}
        image={vitor}
        title="green iguana"
   
      />
      <CardContent>
       <p className='speaker_head'> 
       Vitorino Osorio
</p>
       <p className='speaker_para'> ICT Coordinator and Computer Media Professor, Ibiza School of Art

</p>
      </CardContent>
     
    </Card>
        </SwiperSlide>
      </Swiper>


</Grid>






    </div>
  )
}

export default Speaker