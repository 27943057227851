import * as React from 'react';
import PropTypes from 'prop-types';
import hub from './Venue/hub.png'
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Grid } from "@mui/material";

import English from './EnglishTab'
import Spanish from './SpanishTab'


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function FullWidthTabs() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <>
      <div className='container flex' >





        <a
          onClick={() => handleChange(null, 0)}
          style={{
            backgroundColor: value === 0 ? theme.palette.secondary.main : 'transparent',
            color: value === 0 ? 'white' : 'black',
          }}
          className="btn-grad "
        >
    English
        </a>
        <a
          onClick={() => handleChange(null, 1)}
          style={{
            backgroundColor: value === 1 ? theme.palette.secondary.main : 'transparent',
            color: value === 1 ? 'white' : 'black',
          }}
          className="btn-grad "
        >
       ESPAÑOL
        </a>
      </div>
     
        <TabPanel value={value} index={0} dir={theme.direction}>
                    
<English/>

        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
   
<Spanish/>
        </TabPanel>
    
    </>
  );
}
