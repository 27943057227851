import React from 'react';
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import logo from './footer.png'
import { Facebook } from '@mui/icons-material';
import { Telegram } from '@mui/icons-material';
import { Twitter } from '@mui/icons-material';
import { Instagram } from '@mui/icons-material';
import { GitHub } from '@mui/icons-material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TelegramIcon from '@mui/icons-material/Telegram';

export default function App() {
  return (
    <MDBFooter bgColor='' className='text-center text-lg-start text-muted'>
   

      <section className=''>
        <MDBContainer className='text-center text-md-start mt-5'>
          <MDBRow className='mt-3'>
            <MDBCol md="3" lg="4" xl="3" className='mx-auto mb-4'>
            <img src={logo} style={{width:"80%" , position:"relative" , top:"-70px"}}/>
            
            </MDBCol>

            <MDBCol md="2" lg="2" xl="2" className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4' style={{color:"#fff"}} >Ibiza NXT</h6>
              <p style={{color:"#fff"}}>
                <a href='#registration' className='text-reset' style={{color:"#fff"}}>
                Registration
                </a>
              </p>
              <p>
                <a href='#speaker' className='text-reset' style={{color:"#fff"}}>
                 Speakers
                </a>
              </p>
              <p>
               
              </p>
            </MDBCol>

            <MDBCol md="3" lg="2" xl="2" className='mx-auto mb-4'>
             
              <p>
                <a href='#mediapartner' className='text-reset' style={{color:"#fff"}}>
                Media Partners
                </a>
              </p>
              <p>
                <a href='#partner' className='text-reset' style={{color:"#fff"}}>
                Partners
                </a>
              </p>
             
            </MDBCol>

            <MDBCol md="4" lg="3" xl="3" className='mx-auto mb-md-0 mb-4'>
              <h6 className='text-uppercase fw-bold mb-4' style={{color:"#fff"}}>SOCIAL</h6>
              <p style={{color:"#fff"}}>
              <a href='https://www.facebook.com/ibizatoken/'> <Facebook style={{color:"#fff"}}/>  </a>    <a href='https://www.linkedin.com/company/ibizatoken/'> <LinkedInIcon style={{color:"#fff"}}/>  </a>  <a href='https://twitter.com/IbizaToken'> <Twitter style={{color:"#fff"}}/>  </a>         <a href='https://www.instagram.com/ibizatoken/'> <Instagram style={{color:"#fff"}}/>  </a>               <a href='https://github.com/ibizatoken'> <GitHub style={{color:"#fff"}}/>  </a>    <a href='https://t.me/ibizatoken'> <TelegramIcon style={{color:"#fff"}}/>  </a>                    
               
              </p>
             
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

     
    </MDBFooter>
  );
}