import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { Grid } from "@mui/material";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const Sponsor = () => {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_lmhfuk1",
        "template_l9uurjf",
        form.current,
        "L6wJFodIEsZx5hmCK"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("message sent")
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  const [loadings, setLoadings] = useState([]);
  const enterLoading = (index) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });
    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
    }, 6000);
  };



  return (
    <div className="container" id="registration">
      <br />
      <br />
      <br />
      <Grid container>
        <Grid item md={12} lg={12} xs={12} sm={12}>
          <h1
            style={{
              textAlign: "center",
              color: "#fff",
              fontWeight: "800",
            }}
            className="sponser_title_text"
          >
            Registration
          </h1>
          <p
            style={{
              textAlign: "center",
              color: "#fff",
              fontWeight: "500",
            }}
            className="sponser_under_text"
          >
           Fill out the form, we'll reach
            out to you soon
          </p>
        </Grid>
      </Grid>
      <form ref={form} onSubmit={sendEmail}>
        <Grid container>
          <Grid item md={12} lg={12} xs={12} sm={12}>
            <div class="container">
              <div class="row input-container">
                <div class="col-xs-12 col-sm-12">
                  <div class="styled-input wide">
                    <input type="text" name="from_name_first" required />
                    <label>First name</label>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-12">
                  <div class="styled-input wide">
                    <input type="text" name="from_name_last" required />
                    <label>Last name</label>
                  </div>
                </div>
                <div class="col-md-6 col-sm-12">
                  <div class="styled-input" style={{ float: "right" }}>
                    <input type="text" name="from_email" required />
                    <label>Email</label>
                  </div>
                </div>
                <div class="col-md-6 col-sm-12">
                  <div class="styled-input">
                    <input type="text" name="from_job_title" required />
                    <label>Job Title</label>
                  </div>
                </div>
                <div class="col-md-6 col-sm-12">
                  <div class="styled-input" style={{ float: "right" }}>
                    <input type="text" name="from_company_name" required />
                    <label>Company Name</label>
                  </div>
                </div>
                <div class="col-md-6 col-sm-12">
                  <div class="styled-input">
                    <input type="text" name="from_country_city" required />
                    <label>Country/City</label>
                  </div>
                </div> 
                <div class="col-xs-12 col-sm-12" style={{color:"#fff" , fontWeight:"400"}}>
                <label>You Want to Attend </label>
                </div>
                <div class="col-xs-12 col-sm-12" style={{color:"#fff" , fontWeight:"400"}}>
                <label>Morning section : Afternoon section : Both section</label>
                </div>
                <div class="col-xs-12 col-sm-12">
                  <div class="styled-input wide">
                    <input type="text" name="from_four" required style={{color:"#fff" , fontWeight:"400"}} />
                    <label>4 October </label>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-12" style={{color:"#fff" , fontWeight:"400"}}>
                <label>Morning section : Afternoon section  : Both section </label>
                </div>
                <div class="col-xs-12 col-sm-12">
                  <div class="styled-input wide">
                    <input type="text" name="from_five" required />
                    <label>5 Octrober  </label>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-12" style={{color:"#fff" , fontWeight:"400"}}>
                <label>Morning section : Afternoon section  : Both section</label>
                </div>
                <div class="col-xs-12 col-sm-12">
                  <div class="styled-input wide">
                    <input type="text" name="from_six" required />
                    <label>6 Octrober  </label>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-12" style={{color:"#fff" , fontWeight:"400"}}>
                <label>We will contact you via email about Ibiza Token events, products and services. You can unsubscribe at any time.
</label>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid
            item
            md={12}
            lg={12}
            xs={12}
            sm={12}
            style={{ textAlign: "center" }}
          >
            <div class="col-xs-12 ">
              <div >
                {" "}
                <button
                  type="submit"
                  value="Send"
                  class="btn-lrg submit-btn "
                  onClick={handleClick}
                >
                  Send
                </button>
                <Stack spacing={2} sx={{ width: '100%' }}>
   
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Your Registration is Submitted!
        </Alert>
      </Snackbar>
  
    </Stack>
              </div>
            </div>
          </Grid>
        </Grid>
        <br />
        <br />
        <br />
      </form>
    </div>
  );
};

export default Sponsor;
