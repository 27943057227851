import * as React from 'react';
import PropTypes from 'prop-types';
import hub from './Venue/hub.png'
import ebu from './logos/ebu.png'
import loc from './locas.png'
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Grid } from "@mui/material";

import som from './Somi.png'
import am from './amibi.png'
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function FullWidthTabs() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <>
      <div className='container flex' >





        <a
          onClick={() => handleChange(null, 0)}
          style={{
            backgroundColor: value === 0 ? theme.palette.secondary.main : 'transparent',
            color: value === 0 ? 'white' : 'black',
          }}
          className="btn-grady"
        >
4 October
        </a>
        <a
          onClick={() => handleChange(null, 1)}
          style={{
            backgroundColor: value === 1 ? theme.palette.secondary.main : 'transparent',
            color: value === 1 ? 'white' : 'black',
          }}
          className="btn-grady"
        >
   5 October
        </a>

        <a
          onClick={() => handleChange(null, 2)}
          style={{
            backgroundColor: value === 2 ? theme.palette.secondary.main : 'transparent',
            color: value === 2 ? 'white' : 'black',
          }}
          className="btn-grady"
        >
      6 October
        </a>
      </div>
     
        <TabPanel value={value} index={0} dir={theme.direction}>
                    
        <div class="container">
        <Grid container>
      <Grid item md={12} lg={12} sm={12} xs={12} id='partner' className='centeralldiv'>
      <>     <br/>  <img src={hub} style={{width:"150px"}}/> </>
      <br/>  <br/>  <br/>
        </Grid>

        <Grid item md={12} lg={12} sm={12} xs={12} id='partner' className='centeralldiv'>
      <a  href='https://www.google.com/maps/place/Ca+Na+Palava/@38.9587059,1.4438436,17z/data=!3m1!4b1!4m6!3m5!1s0x129940b5608e66c7:0xbc18f468c44ba18a!8m2!3d38.9587059!4d1.4438436!16s%2Fg%2F11fyxd5vsl?entry=ttu' target='_blank' >     <br/>  <img src={loc} style={{width:"120px"}}  className='vert-move'/> </a>
       <br/>
        </Grid>
      </Grid>
      <br/>  <br/>  <br/>
  <div class="timeline">
    <div class="timeline-month">
    Wednesday 4 - 10 - 23   
    </div>
    
    <div class="timeline-section">
      <div class="timeline-date">
      10.30
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            
            <div class="box-content">
              <a class="" style={{fontSize:"20px" , color:"#fff"}}>ENTRANCE / BREAKFAST</a>
    
            </div>
        
          </div>
        </div>
    

      </div>

    </div>

    <div class="timeline-section">
      <div class="timeline-date">
      11.00 -11.05 - Intro
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-pencil text-info" aria-hidden="true"></i> Speakers 
            </div>
            <div class="box-content">
             
              <div class="box-item">Cripto Willy<span style={{color:"#00ccc5"}}> - HOST / MODERATOR </span></div>
              <div class="box-item">Ana Lucía Lara <span style={{color:"#00ccc5"}}> - HOST / MODERATOR </span> </div>
              <div class="box-item">Carmen Pi <span style={{color:"#00ccc5"}}> - HOST / MODERATOR </span></div>

            </div>
          
          </div>
        </div>
      
        <div class="col-sm-6">  <br/>
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-tasks text-primary" aria-hidden="true"></i>Title
            </div>
            <div class="box-footer">
            Welcome  to Ibiza NXT 2023: Heading toward a purpose-driven innovation journey
          
           </div>
          </div>
        </div>
    
      </div>
 

    </div>
    <div class="timeline-section">
      <div class="timeline-date">
      11:05 - 11:40 - Talk 
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-pencil text-info" aria-hidden="true"></i> Speakers 
            </div>
            <div class="box-content">
             
              <div class="box-item">Alek Hannessian<span style={{color:"#00ccc5"}}> - Head of Partnerships & Sales, Ethermail  </span></div>
             

            </div>
          
          </div>
        </div>
        <div class="col-sm-6"> <br/>
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-tasks text-primary" aria-hidden="true"></i>Title
            </div>
            <div class="box-footer">
            Bridging the communication gap in the Decentralized space: Effective connections between users and projects
          
           </div>
          </div>
        </div>
    
      </div>
 

    </div>
    <div class="timeline-section">
      <div class="timeline-date">
      11:40 - 12:15 - Talk 
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-pencil text-info" aria-hidden="true"></i> Speakers 
            </div>
            <div class="box-content">
             
              <div class="box-item">Rosa Pericàs Gornals <span style={{color:"#00ccc5"}}> - Balearic Island University Ph student  </span></div>
             

            </div>
          
          </div>
        </div>
        <div class="col-sm-6"> <br/>
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-tasks text-primary" aria-hidden="true"></i>Title
            </div>
            <div class="box-footer">
            Enhancing Decentralized Communication: Introducing Confidential Certified Notifications
          
           </div>
          </div>
        </div>
    
      </div>
 

    </div>
    <div class="timeline-section">
      <div class="timeline-date">
      12:15 - 12:50 - Talk 
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-pencil text-info" aria-hidden="true"></i> Speakers 
            </div>
            <div class="box-content">
             
              <div class="box-item">Zeno Auersperg  <span style={{color:"#00ccc5"}}> -  Head of Economic Research, Folks Finance  </span></div>
             

            </div>
          
          </div>
        </div>
        <div class="col-sm-6"> <br/>
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-tasks text-primary" aria-hidden="true"></i>Title
            </div>
            <div class="box-footer">
            Revolutionizing Finance: Decentralization for a more democratic system and tangible real-life benefits
          
           </div>
          </div>
        </div>
    
      </div>
 

    </div>
    <div class="timeline-section">
      <div class="timeline-date">
      12:50 - 13.30 - Talk 
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-pencil text-info" aria-hidden="true"></i> Speakers 
            </div>
            <div class="box-content">
             
              <div class="box-item">Alex Nascimento <span style={{color:"#00ccc5"}}> -  Managing director 7CC investments / faculty and co-founder Blockchain at UCLA  </span></div>
             

            </div>
          
          </div>
        </div>
        <div class="col-sm-6"> <br/>
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-tasks text-primary" aria-hidden="true"></i>Title
            </div>
            <div class="box-footer">
            Frontier Technologies (Blockchain, AI/VR, IoT): Revolutionizing Business and Financial Services.
          
           </div>
          </div>
        </div>
    
      </div>
 

    </div>
    <div class="timeline-section">
      <div class="timeline-date">
      13:30 - 14.05 - Panel 
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-pencil text-info" aria-hidden="true"></i> Speakers 
            </div>
            <div class="box-content">
             
              <div class="box-item">Jonathan Napolitano<span style={{color:"#00ccc5"}}> -  Founder & CTO of X-R3AL / Researcher </span></div>
              <div class="box-item"> Luiza Castro Rey<span style={{color:"#00ccc5"}}>  - Partner and  Head of Business and Web3 at  FIO Legal solutions </span></div>
             

            </div>
          
          </div>
        </div>
        <div class="col-sm-6"> <br/>
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-tasks text-primary" aria-hidden="true"></i>Title
            </div>
            <div class="box-footer">
            The Asset Tokenization Revolution: innovative solutions for real-world challenges and regulatory compliance
          
           </div>
          </div>
        </div>
    
      </div>
 

    </div>
    <div class="timeline-section">
      <div class="timeline-date">
      14:05 - 14:35 - Talk
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-pencil text-info" aria-hidden="true"></i> Speakers 
            </div>
            <div class="box-content">
             
              <div class="box-item"> Bob Reid<span style={{color:"#00ccc5"}}> -   CEO and co-founder of Everest.org </span></div>
             

            </div>
          
          </div>
        </div>
        <div class="col-sm-6"> <br/>
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-tasks text-primary" aria-hidden="true"></i>Title
            </div>
            <div class="box-footer">
            Towards Decentralized Finance Mass Adoption: Digital Identity Verification
          
           </div>
          </div>
        </div>
    
      </div>
 

    </div>
    <div class="timeline-section">
      <div class="timeline-date">
      14:35
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            
            <div class="box-content">
              <a class="" style={{fontSize:"20px" , color:"#fff"}}>NETWORKING LAUNCH</a>
    
            </div>
        
          </div>
        </div>
    

      </div>

    </div>

  </div>
</div>
<div class="container">
        <Grid container>
      <Grid item md={12} lg={12} sm={12} xs={12} id='partner' className='centeralldiv'>
      <>     <br/>  <br/>  <br/> <h1 class="parahead1l">Baluarte de Sant Pere</h1> </>
       <br/>  <br/>  <br/>
        </Grid>

        <Grid item md={12} lg={12} sm={12} xs={12} id='partner' className='centeralldiv'>
      <a href='https://www.google.com/maps/dir//Pla%C3%A7a+del+Sol,+9,+07800+Eivissa,+Illes+Balears/@38.9080289,1.4330566,19z/data=!4m8!4m7!1m0!1m5!1m1!1s0x129946b078b178eb:0xf7ba92d4e5a28b82!2m2!1d1.4337038!2d38.9080579?entry=ttu'  target='_blank'>     <br/>  <img src={loc} style={{width:"120px"}}  className='vert-move'/> </a>
       <br/>
        </Grid>
      </Grid>
      <br/>  <br/>  <br/>
  <div class="timeline">
    <div class="timeline-month">
    Wednesday 4 - 10 - 23   
    </div>
    
  

    <div class="timeline-section">
      <div class="timeline-date">
      18.30 - 19.10 
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-pencil text-info" aria-hidden="true"></i> Speakers 
            </div>
            <div class="box-content">
              <div class="box-item">Rubén Sousa<span style={{color:"#00ccc5"}}> -  City of Ibiza Councillor for Smart City, Urban Transport, and European Funds  </span></div>
            </div>
          
          </div>
        </div>
      
        <div class="col-sm-6">  <br/>
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-tasks text-primary" aria-hidden="true"></i>Title
            </div>
            <div class="box-footer">
            Interview
          
           </div>
          </div>
        </div>
    
      </div>
 

    </div>
    <div class="timeline-section">
      <div class="timeline-date">
      19.10 - 19.50 
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-pencil text-info" aria-hidden="true"></i> Speakers 
            </div>
            <div class="box-content">
             
              <div class="box-item">Bruce Sterling<span style={{color:"#00ccc5"}}> - Science Fiction author and Founder of Cyberpunk movement  </span></div>
             

            </div>
          
          </div>
        </div>
        <div class="col-sm-6"> <br/>
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-tasks text-primary" aria-hidden="true"></i>Title
            </div>
            <div class="box-footer">
            Interview
          
           </div>
          </div>
        </div>
    
      </div>
 

    </div>
    <div class="timeline-section">
      <div class="timeline-date">
      19.50 - 20.30 
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-pencil text-info" aria-hidden="true"></i> Speakers 
            </div>
            <div class="box-content">
             
              <div class="box-item">Léa Leuzinger <span style={{color:"#00ccc5"}}> - Earth Scientist & Wildlife Specialist at Bibo Ibiza Biotech Park  </span></div>
             

            </div>
          
          </div>
        </div>
        <div class="col-sm-6"> <br/>
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-tasks text-primary" aria-hidden="true"></i>Title
            </div>
            <div class="box-footer">
            Interview 
          
           </div>
          </div>
        </div>
    
      </div>
 

    </div>

    <div class="timeline-section">
      <div class="timeline-date">
      20.30 onward 
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            
            <div class="box-content">
              <a class="" style={{fontSize:"20px" , color:"#fff"}}>NFT Exhibition, Dj Set and live acts</a>
    
            </div>
        
          </div>
        </div>
    

      </div>

    </div>

  </div>
</div>
        </TabPanel>

        <TabPanel value={value} index={1} dir={theme.direction}>
   
        <div class="container">
        <Grid container>
      <Grid item md={12} lg={12} sm={12} xs={12} id='partner' className='centeralldiv'>
      <>     <br/>  <img src={hub} style={{width:"150px"}}/> </>
      <br/>  <br/>  <br/>
        </Grid>

        <Grid item md={12} lg={12} sm={12} xs={12} id='partner' className='centeralldiv'>
      <a  href='https://www.google.com/maps/place/Ca+Na+Palava/@38.9587059,1.4438436,17z/data=!3m1!4b1!4m6!3m5!1s0x129940b5608e66c7:0xbc18f468c44ba18a!8m2!3d38.9587059!4d1.4438436!16s%2Fg%2F11fyxd5vsl?entry=ttu' target='_blank' >     <br/>  <img src={loc} style={{width:"120px"}}  className='vert-move'/> </a>
       <br/>
        </Grid>
      </Grid>
      <br/>  <br/>  <br/>
   
  <div class="timeline">
    <div class="timeline-month">
    Thursday 5 - 10 - 23   
    </div>
    
    <div class="timeline-section">
      <div class="timeline-date">
      10.30
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            
            <div class="box-content">
              <a class="" style={{fontSize:"20px" , color:"#fff"}}>ENTRANCE / BREAKFAST</a>
    
            </div>
        
          </div>
        </div>
    

      </div>

    </div>

    <div class="timeline-section">
      <div class="timeline-date">
      11.00 - 11.30 - Talk
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-pencil text-info" aria-hidden="true"></i> Speakers 
            </div>
            <div class="box-content">
             
              <div class="box-item">Andrew Bonneau <span style={{color:"#00ccc5"}}> - Head of partnerships, Klima DAO </span></div>
           

            </div>
          
          </div>
        </div>
      
        <div class="col-sm-6">  <br/>
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-tasks text-primary" aria-hidden="true"></i>Title
            </div>
            <div class="box-footer">
            Sustainability in Action: The Next Generation of Climate Finance
           </div>
          </div>
        </div>
    
      </div>
 

    </div>
    <div class="timeline-section">
      <div class="timeline-date">
      11.30 - 12.00 - Talk
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-pencil text-info" aria-hidden="true"></i> Speakers 
            </div>
            <div class="box-content">
             
              <div class="box-item">Javier Gonzaled Granado<span style={{color:"#00ccc5"}}> - Notary  </span></div>
             

            </div>
          
          </div>
        </div>
        <div class="col-sm-6"> <br/>
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-tasks text-primary" aria-hidden="true"></i>Title
            </div>
            <div class="box-footer">
            Understanding the Notary's function in cryptocurrency transactions
          
           </div>
          </div>
        </div>
    
      </div>
 

    </div>
    <div class="timeline-section">
      <div class="timeline-date">
      12.00 - 12:30 - Panel
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-pencil text-info" aria-hidden="true"></i> Speakers 
            </div>
            <div class="box-content">
             
              <div class="box-item">Eva Cano <span style={{color:"#00ccc5"}}> - Promotion department technical officer, PIMEEF   </span></div>
             
              <div class="box-item">Davide Costa <span style={{color:"#00ccc5"}}> - Co-founder Quadrans Foundation    </span></div>
            </div>
          
          </div>
        </div>
        <div class="col-sm-6"> <br/>
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-tasks text-primary" aria-hidden="true"></i>Title
            </div>
            <div class="box-footer">
            Preserving "Made-In" Products: How Blockchain Enhances and Supports Local Excellence
          
           </div>
          </div>
        </div>
    
      </div>
 

    </div>
    <div class="timeline-section">
      <div class="timeline-date">
      12:30 - 13:00 - Talk 
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-pencil text-info" aria-hidden="true"></i> Speakers 
            </div>
            <div class="box-content">
             
              <div class="box-item">Sam Wellalge<span style={{color:"#00ccc5"}}> - Founder and Principal Recruiter , WorkInCrypto Global  </span></div>
             

            </div>
          
          </div>
        </div>
        <div class="col-sm-6"> <br/>
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-tasks text-primary" aria-hidden="true"></i>Title
            </div>
            <div class="box-footer">
            Building a sustainable career Path in Web3 and Tech: A roadmap to success
          
           </div>
          </div>
        </div>
    
      </div>
 

    </div>
    <div class="timeline-section">
      <div class="timeline-date">
      13.00 - 13.30 -  Panel 
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-pencil text-info" aria-hidden="true"></i> Speakers 
            </div>
            <div class="box-content">
             
              <div class="box-item">Chris Biele -   AKA NF Thinker <span style={{color:"#00ccc5"}}>  - Bankless DAO core contributor  </span></div>
             
              <div class="box-item"> Reinis Kalnins <span style={{color:"#00ccc5"}}> -  Bankless DAO contributor and coordinator  </span></div>
            </div>
          
          </div>
        </div>
        <div class="col-sm-6"> <br/>
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-tasks text-primary" aria-hidden="true"></i>Title
            </div>
            <div class="box-footer">
            Developing Web3 projects: Funding your community
          
           </div>
          </div>
        </div>
    
      </div>
 

    </div>
    <div class="timeline-section">
      <div class="timeline-date">
      13.30 - 14:00 - Panel 
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-pencil text-info" aria-hidden="true"></i> Speakers 
            </div>
            <div class="box-content">
             
              <div class="box-item">Cynthia Huang<span style={{color:"#00ccc5"}}> -  CEO of Dtravel </span></div>
              <div class="box-item">  Laura Perea<span style={{color:"#00ccc5"}}> - CX strategist and Guest Experience Manager, Barcelo hotel Group</span></div>
             
            
            </div>
          
          </div>
        </div>
        <div class="col-sm-6"> <br/>
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-tasks text-primary" aria-hidden="true"></i>Title
            </div>
            <div class="box-footer">
            The Future of Tourism: Impact and Influence of Blockchain and Emerging Technologies
          
           </div>
          </div>
        </div>
    
      </div>
 

    </div>
    <div class="timeline-section">
      <div class="timeline-date">
      14.00 - 14:30 - Talk
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-pencil text-info" aria-hidden="true"></i> Speakers 
            </div>
            <div class="box-content">
             
              <div class="box-item">Helena Rong<span style={{color:"#00ccc5"}}> - PhD Researcher, Columbia University </span></div>
             

            </div>
          
          </div>
        </div>
        <div class="col-sm-6"> <br/>
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-tasks text-primary" aria-hidden="true"></i>Title
            </div>
            <div class="box-footer">
            From Community Currency to Crypto City Tokens: Potentials, Shortfalls, and Future Outlooks of New Old Ideas

          
           </div>
          </div>
        </div>
    
      </div>
 

    </div>
    <div class="timeline-section">
      <div class="timeline-date">
      14:30
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            
            <div class="box-content">
              <a class="" style={{fontSize:"20px" , color:"#fff"}}>NETWORKING LAUNCH</a>
    
            </div>
        
          </div>
        </div>
    

      </div>

    </div>

  </div>
</div>
<div class="container">
<Grid container>
      <Grid item md={12} lg={12} sm={12} xs={12} id='partner' className='centeralldiv'>
      <>     <br/>  <br/>  <br/> <h1 class="parahead1l">Baluarte de Sant Pere</h1> </>
       <br/>  <br/>  <br/>
        </Grid>

        <Grid item md={12} lg={12} sm={12} xs={12} id='partner' className='centeralldiv'>
      <a href='https://www.google.com/maps/dir//Pla%C3%A7a+del+Sol,+9,+07800+Eivissa,+Illes+Balears/@38.9080289,1.4330566,19z/data=!4m8!4m7!1m0!1m5!1m1!1s0x129946b078b178eb:0xf7ba92d4e5a28b82!2m2!1d1.4337038!2d38.9080579?entry=ttu'  target='_blank'>     <br/>  <img src={loc} style={{width:"120px"}}  className='vert-move'/> </a>
       <br/>
        </Grid>
      </Grid>
      <br/>  <br/>  <br/>
   
  <div class="timeline">
    <div class="timeline-month">
    Thursday 5 - 10 - 23   
    </div>
    
  

    <div class="timeline-section">
      <div class="timeline-date">
      18.30 - 19.10 
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-pencil text-info" aria-hidden="true"></i> Speakers 
            </div>
            <div class="box-content">
              <div class="box-item">Francisco José Perales <span style={{color:"#00ccc5"}}> - Full Professor and Director of the Innovation Unit in Video Games and Artificial Intelligence, University of the Balearic Islands</span></div>
            </div>
          
          </div>
        </div>
      
        <div class="col-sm-6">  <br/>
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-tasks text-primary" aria-hidden="true"></i>Title
            </div>
            <div class="box-footer">
            Interview
          
           </div>
          </div>
        </div>
    
      </div>
 

    </div>
    <div class="timeline-section">
      <div class="timeline-date">
      19.10 - 19.50 
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-pencil text-info" aria-hidden="true"></i> Speakers 
            </div>
            <div class="box-content">
             
              <div class="box-item">Jasmina Tešanović<span style={{color:"#00ccc5"}}> - Writer ad activist</span></div>
             

            </div>
          
          </div>
        </div>
        <div class="col-sm-6"> <br/>
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-tasks text-primary" aria-hidden="true"></i>Title
            </div>
            <div class="box-footer">
            Interview
          
           </div>
          </div>
        </div>
    
      </div>
 

    </div>
    <div class="timeline-section">
      <div class="timeline-date">
      19.50 - 20.30 
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-pencil text-info" aria-hidden="true"></i> Speakers 
            </div>
            <div class="box-content">
             
              <div class="box-item">Alen Valdivia<span style={{color:"#00ccc5"}}> - Founder of Transcendence Platform </span></div>
             

            </div>
          
          </div>
        </div>
        <div class="col-sm-6"> <br/>
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-tasks text-primary" aria-hidden="true"></i>Title
            </div>
            <div class="box-footer">
            Interview 
          
           </div>
          </div>
        </div>
    
      </div>
 

    </div>

    <div class="timeline-section">
      <div class="timeline-date">
      20.30 onward
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            
            <div class="box-content">
              <a class="" style={{fontSize:"20px" , color:"#fff"}}>NFT Exhibition, Dj Set and live acts</a>
    
            </div>
        
          </div>
        </div>
    

      </div>

    </div>

  </div>
</div>
        </TabPanel>


        <TabPanel value={value} index={2} dir={theme.direction}>
   
        <div class="container">
        <Grid container>
      <Grid item md={12} lg={12} sm={12} xs={12} id='partner' className='centeralldiv'>
      <>     <br/>  <img src={hub} style={{width:"150px"}}/> </>
      <br/>  <br/>  <br/>
        </Grid>

        <Grid item md={12} lg={12} sm={12} xs={12} id='partner' className='centeralldiv'>
      <a  href='https://www.google.com/maps/place/Ca+Na+Palava/@38.9587059,1.4438436,17z/data=!3m1!4b1!4m6!3m5!1s0x129940b5608e66c7:0xbc18f468c44ba18a!8m2!3d38.9587059!4d1.4438436!16s%2Fg%2F11fyxd5vsl?entry=ttu' target='_blank' >     <br/>  <img src={loc} style={{width:"120px"}}  className='vert-move'/> </a>
       <br/>
        </Grid>
      </Grid>
      <br/>  <br/>  <br/>
   
  <div class="timeline">
    <div class="timeline-month">
    Friday 6 - 10 - 23   
    </div>
    
    <div class="timeline-section">
      <div class="timeline-date">
      10.30
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            
            <div class="box-content">
              <a class="" style={{fontSize:"20px" , color:"#fff"}}>ENTRANCE / BREAKFAST</a>
    
            </div>
        
          </div>
        </div>
    

      </div>

    </div>

    <div class="timeline-section">
      <div class="timeline-date">
      11.00 - 11.30 - PANEL
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-pencil text-info" aria-hidden="true"></i> Speakers 
            </div>
            <div class="box-content">
             
            <div class="box-item"> Jessica Surendorff <span style={{color:"#00ccc5"}}> - Project manager and business development, Tokentraxx</span></div>
           
              <div class="box-item">Sarah Main  <span style={{color:"#00ccc5"}}> - Multidisciplinary Artist  </span></div>


            
            </div>
          
          </div>
        </div>
      
        <div class="col-sm-6">  <br/>
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-tasks text-primary" aria-hidden="true"></i>Title
            </div>
            <div class="box-footer">
            The Future of Music: Embracing the Era of AI-Generated Content and Decentralization
           </div>
          </div>
        </div>
    
      </div>
 

    </div>
    <div class="timeline-section">
      <div class="timeline-date">
      11.30 - 12.00 - Talk
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-pencil text-info" aria-hidden="true"></i> Speakers 
            </div>
            <div class="box-content">
             
              <div class="box-item">Jurgis Didžiulis (aka Jurgis DID)<span style={{color:"#00ccc5"}}> -  Speaker, Educator, Musician and Activist </span></div>
             

            </div>
          
          </div>
        </div>
        <div class="col-sm-6"> <br/>
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-tasks text-primary" aria-hidden="true"></i>Title
            </div>
            <div class="box-footer">
           
            Leveraging human connection in an increasingly digital world
            
          
           </div>
          </div>
        </div>
    
      </div>
 

    </div>
    <div class="timeline-section">
      <div class="timeline-date">
      12.00 - 12:30 - Panel
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-pencil text-info" aria-hidden="true"></i> Speakers 
            </div>
            <div class="box-content">
             
              <div class="box-item">Violeta M.Valcheva  <span style={{color:"#00ccc5"}}> - Founder of NFEST, VVs Productions   </span></div>
             
              <div class="box-item">  Laura Viana<span style={{color:"#00ccc5"}}> -  Lawyer,  Fio Legal solutions     </span></div>
            </div>
          
          </div>
        </div>
        <div class="col-sm-6"> <br/>
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-tasks text-primary" aria-hidden="true"></i>Title
            </div>
            <div class="box-footer">
            Content creation's new Horizons: AI, Web3, Tokenization – Opportunities & Legal Risks


          
           </div>
          </div>
        </div>
    
      </div>
 

    </div>
    <div class="timeline-section">
      <div class="timeline-date">
      12:30 - 13:00 - Panel
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-pencil text-info" aria-hidden="true"></i> Speakers 
            </div>
            <div class="box-content">
             
              <div class="box-item">Tommy D <span style={{color:"#00ccc5"}}> -  Founder and creator of TokenTraxx </span></div>
              <div class="box-item"> Arnold Travieso  <span style={{color:"#00ccc5"}}> - Founder & CEO Crypto Música Records  </span></div>

            </div>
          
          </div>
        </div>
        <div class="col-sm-6"> <br/>
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-tasks text-primary" aria-hidden="true"></i>Title
            </div>
            <div class="box-footer">
            Web3 & Music: New Fan Engagement Strategies for Artists and Record Labels
          
           </div>
          </div>
        </div>
    
      </div>
 

    </div>
    <div class="timeline-section">
      <div class="timeline-date">
      13.00 - 13.50 - Panel
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-pencil text-info" aria-hidden="true"></i> Speakers 
            </div>
            <div class="box-content">
             
              <div class="box-item">Anatoli Ille<span style={{color:"#00ccc5"}}> - Co-founder , DEXART Metaverse </span></div>
             
              <div class="box-item"> Anton Elston  <span style={{color:"#00ccc5"}}> - Co-founder , DEXART Metaverse   </span></div>
              <div class="box-item"> Patrick Scheire  <span style={{color:"#00ccc5"}}> - Partener, ibizaToken  </span></div>
            </div>
          
          </div>
        </div>
        <div class="col-sm-6"> <br/>
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-tasks text-primary" aria-hidden="true"></i>Title
            </div>
            <div class="box-footer">
            IbizaMeta: Bringing the 'White Island' essence to the Metaverse
          
           </div>
          </div>
        </div>
    
      </div>
 

    </div>


    
    <div class="timeline-section">
      <div class="timeline-date">
      13.50 - 14:20 - TALK
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-pencil text-info" aria-hidden="true"></i> Speakers 
            </div>
            <div class="box-content">
             
            <div class="box-item">Santiago Vich<span style={{color:"#00ccc5"}}> -  Head of Development and Promotion , Ibiza School of Art  </span></div>
              <div class="box-item"> Vitorino Osorio  <span style={{color:"#00ccc5"}}> - ICT Coordinator Computer Media Professor, Ibiza School of Art School of Art  </span> </div>
              <div class="box-item">Manel Ortí <span style={{color:"#00ccc5"}}> - Head of Forging and Woodworking, Volume Professor, Ibiza  </span></div>
             

            </div>
          
          </div>
        </div>
        <div class="col-sm-6"> <br/>
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-tasks text-primary" aria-hidden="true"></i>Title
            </div>
            <div class="box-footer">
            A FabLab at the Ibiza School of Art 
          
           </div>
          </div>
        </div>
    
      </div>
 

    </div>
    
    <div class="timeline-section">
      <div class="timeline-date">
      14.20 - 14:30 - TALK
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-pencil text-info" aria-hidden="true"></i> Speakers 
            </div>
            <div class="box-content">
             
            <div class="box-item">Cripto Willy<span style={{color:"#00ccc5"}}> - HOST / MODERATOR </span></div>
              <div class="box-item">Ana Lucía Lara <span style={{color:"#00ccc5"}}> - HOST / MODERATOR </span> </div>
              <div class="box-item">Carmen Pi <span style={{color:"#00ccc5"}}> - HOST / MODERATOR </span></div>

             

            </div>
          
          </div>
        </div>
        <div class="col-sm-6"> <br/>
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-tasks text-primary" aria-hidden="true"></i>Title
            </div>
            <div class="box-footer">
            Final Greetings and appointment to Ebusus
          
           </div>
          </div>
        </div>
    
      </div>
 

    </div>
    <div class="timeline-section">
      <div class="timeline-date">
     14:30
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            
            <div class="box-content">
              <a class="" style={{fontSize:"20px" , color:"#fff"}}>NETWORKING LAUNCH</a>
    
            </div>
        
          </div>
        </div>
    

      </div>

    </div>

  </div>
</div>
<div class="container">
     
      <Grid container>
      <Grid item md={12} lg={12} sm={12} xs={12} id='partner' className='centeralldiv'>
      <>     <br/>  <br/>  <br/> <img src={ebu} style={{width:"150px"}}/>  </>
       <br/>  <br/>  <br/>
        </Grid>

        <Grid item md={12} lg={12} sm={12} xs={12} id='partner' className='centeralldiv'>
      <a href='https://www.google.com/maps/place/Sociedad+Cultural+Ebusus/@38.9092149,1.4329932,15z/data=!4m6!3m5!1s0x129947eab4e1372f:0x6d00316efcb3c5ea!8m2!3d38.9092149!4d1.4329932!16s%2Fg%2F11s8qssk6n?entry=ttu'  target='_blank'>     <br/>  <img src={loc} style={{width:"120px"}} className='vert-move'/> </a>
       <br/>
        </Grid>
      </Grid>
      <br/>  <br/>  <br/>
  <div class="timeline">
    <div class="timeline-month">
   Friday 6 - 10 - 23   
    </div>
    
  

    <div class="timeline-section">
      <div class="timeline-date">
      18.30 - 19.10 
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-pencil text-info" aria-hidden="true"></i> Speakers 
            </div>
            <div class="box-content">
              <div class="box-item">Sarah Main<span style={{color:"#00ccc5"}}> -  Artist  </span></div>
            </div>
          
          </div>
        </div>
      
        <div class="col-sm-6">  <br/>
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-tasks text-primary" aria-hidden="true"></i>Title
            </div>
            <div class="box-footer">
            Interview
          
           </div>
          </div>
        </div>
    
      </div>
 

    </div>
    <div class="timeline-section">
      <div class="timeline-date">
      19.10 - 19.50 
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-pencil text-info" aria-hidden="true"></i> Speakers 
            </div>
            <div class="box-content">
             
              <div class="box-item">Cripto Willy<span style={{color:"#00ccc5"}}> - Populazier and Influencer</span></div>
             

            </div>
          
          </div>
        </div>
        <div class="col-sm-6"> <br/>
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-tasks text-primary" aria-hidden="true"></i>Title
            </div>
            <div class="box-footer">
            Interview
          
           </div>
          </div>
        </div>
    
      </div>
 

    </div>
    <div class="timeline-section">
      <div class="timeline-date">
      19.50 - 20.30 
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-pencil text-info" aria-hidden="true"></i> Speakers 
            </div>
            <div class="box-content">
             
              <div class="box-item">Jordi Gómez <span style={{color:"#00ccc5"}}> - Artist</span></div>
             

            </div>
          
          </div>
        </div>
        <div class="col-sm-6"> <br/>
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-tasks text-primary" aria-hidden="true"></i>Title
            </div>
            <div class="box-footer">
            Interview 
          
           </div>
          </div>
        </div>
    
      </div>
 

    </div>

    <div class="timeline-section">
      <div class="timeline-date">
      20:30 
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
            
            <div class="box-content">
              <a class="" style={{fontSize:"20px" , color:"#fff"}}>CLOSING LATE AFTERNOON SESSIONS</a>
    
            </div>
        
          </div>
        </div>
    

      </div>

    </div>

  </div>
</div>





<div class="container">
     
      <Grid container>
      <Grid item md={12} lg={12} sm={12} xs={12} id='partner' className='centeralldiv'>
      <>     <br/>  <br/>  <br/> <img src={som} style={{width:"150px"}}/>  </>
       <br/>  <br/>  <br/>
        </Grid>

        <Grid item md={12} lg={12} sm={12} xs={12} id='partner' className='centeralldiv'>
      <a href='https://www.google.com/maps/place/Somiart/@38.9822326,1.5331835,15z/data=!4m6!3m5!1s0x1299154dda5cbf91:0xebcf7021ec3ed0c3!8m2!3d38.9822326!4d1.5331835!16s%2Fg%2F1q5bkqxg9?entry=ttu'  target='_blank'>     <br/>  <img src={loc} style={{width:"120px"}}  className='vert-move'/> </a>
       <br/>
        </Grid>
      </Grid>
      <br/>  <br/>  <br/>
  <div class="timeline">
    <div class="timeline-month">
   Friday 6 - 10 - 23   
    </div>
    
  

    <div class="timeline-section">
      <div class="timeline-date">
      9 PM onward
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
           
            <div class="box-content">
              <div class="box-item">Side event<span style={{color:"#00ccc5"}}>   </span></div>
            </div>
          
          </div>
        </div>
      
      
    
      </div>
 

    </div>
   

  </div>
</div>













<div class="container">
     
      <Grid container>
      <Grid item md={12} lg={12} sm={12} xs={12} id='partner' className='centeralldiv'>
      <>     <br/>  <br/>  <br/> <img src={am} style={{width:"150px"}}/>  </>
       <br/>  <br/>  <br/>
        </Grid>

        <Grid item md={12} lg={12} sm={12} xs={12} id='partner' className='centeralldiv'>
      <a href='https://www.google.com/maps/place/Amnesia+Ibiza/@38.9476661,1.4080028,15z/data=!4m2!3m1!1s0x0:0xaf57624bad153856?sa=X&ved=2ahUKEwi31OTKqtiBAxU8gv0HHYygAkAQ_BJ6BAhAEAA&ved=2ahUKEwi31OTKqtiBAxU8gv0HHYygAkAQ_BJ6BAhcEAg'  target='_blank'>     <br/>  <img src={loc} style={{width:"120px"}}  className='vert-move'/> </a>
       <br/>
        </Grid>
      </Grid>
      <br/>  <br/>  <br/>
  <div class="timeline">
    <div class="timeline-month">
   Friday 6 - 10 - 23   
    </div>
    
  

    <div class="timeline-section">
      <div class="timeline-date">
      12 AM onward
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="timeline-box">
           
            <div class="box-content">
              <div class="box-item">Side event<span style={{color:"#00ccc5"}}>   </span></div>
            </div>
          
          </div>
        </div>
      
      
    
      </div>
 

    </div>
   

  </div>
</div>



   </TabPanel>



    </>
  );
}
