import { useRef } from "react";
import {
  FaBars,
  FaTimes,
} from "react-icons/fa";
import "./Navbar.css";
import lok from "./lok.png";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

function Navbar() {
  const navRef = useRef();
  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };

  return (
    <div baseColor="#482b94" highlightColor="#583e9e" className="head">
      {" "}
      <div className="gradient-top"></div>
      <div className="gradient-bottom"></div>
      <header>
        <a href="./">
          <img src={lok} />
        </a>
        <nav ref={navRef}>
          <a href="./" id="style-2" className="font_nav_text a">
            Home
          </a>
          <a href="/#registration" id="style-2" className="font_nav_text a">
          Registration
          </a>
          <a href="/#speaker" id="style-2" className="font_nav_text a">
        Speakers
          </a>
          <DropdownButton
            id="dropdown-basic-button"
            title=" Previous"
            style={{ fontSize: "20px" }}
          >
            <Dropdown.Item href="https://nxt2021.vercel.app/Nxt.html" target="_blank">
              NXT 2021
            </Dropdown.Item>
            <Dropdown.Item href="https://nxt2022.vercel.app/" target="_blank">
              NXT 2022
            </Dropdown.Item>
          </DropdownButton>

          <a href="./Agenda" id="style-2" className="font_nav_text a">
          Agenda
          </a>
          <button className="nav-btn nav-close-btn" onClick={showNavbar}>
            <FaTimes />
          </button>
        </nav>
        <button className="nav-btn" onClick={showNavbar}>
          <FaBars />
        </button>
      </header>
    </div>
  );
}

export default Navbar;
